import React, { Fragment } from "react";
import css from "./styles/RandomReview.module.scss";
import Label from "../Label/Label";
import util from "../util/util";
const RandomReview = (props) => {
	return (
		<Fragment>
			<div className={css.review}>
				<div>
					<img className={css.img} src={props.userPhoto} />
				</div>

				<div>
					<h6 className={css.heading}>
						<Label className={css.label} color="blue">
							{props.reviewAvgRating}
						</Label>
						{props.reviewTitle}
					</h6>
					<p className={css.text}>
						{util.getText(props.reviewText, 240)}
					</p>
				</div>
			</div>
			<div className="ut-theme-color">
				{props.itemsLength > 1 ? (
					<button
						onClick={props.scrollBtnClick}
						className={util.join(css["button"], css["button-more"])}
					>
						Еще отзывы
					</button>
				) : null}
				<button
					disabled={props.postedReview}
					onClick={props.postBtnClick}
					className={util.join(css["button"], css["button-post"])}
				>
					<span>
						{props.postedReview
							? "Отзыв отправлен"
							: "Написать отзыв"}
					</span>
				</button>
			</div>
		</Fragment>
	);
};

export default RandomReview;
