import React, { useState, Fragment } from "react";
import TimeChart from "./TimeChart";
import css from "./styles/TimePicker.module.scss";

const TimePicker = props => {
  let [pickerVisible, setPickerVisible] = useState(props.toggleVisible);
  let time = props.time.split(":");
  const hour = time[0];
  const minutes = time[1];
  let defaultValue = props.time;
  let classes = [css["picker-input"]];
  if (props.more) {
    classes.push(css.search);
  }
  let chart = (
    <TimeChart
      hour={hour}
      minutes={minutes}
      steps={15}
      pickHandler={(h, m) => {
        setPickerVisible(false);
        props.pickHandler(h, m);
      }}
      hideHandler={() => setPickerVisible(!pickerVisible)}
    />
  );

  return (
    <Fragment>
      {/* <div onClick={() => setPickerVisible(!pickerVisible)}></div> */}
      <input
        type="text"
        onClick={() => setPickerVisible(!pickerVisible)}
        value={defaultValue}
        readOnly
        className={classes.join(" ")}
      />
      {pickerVisible ? chart : null}
    </Fragment>
  );
};

export default TimePicker;
{
  /* <TimePicker
              more={true}
              time={props.filter.time}
              pickHandler={(h, m) => {
                props.setFilter({ time: h + ":" + m });
              }}
              notTime={false}
              hideHandler={setVisible}
              visible={visible}
            /> */
}
