import React, { Fragment } from "react";
import css from "./Hero.module.scss";
import style from "../SearchResults/SearchResults.module.scss";
import util from "../util/util";
import { Link } from "react-router-dom";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
const Offer = (props) => {
	const labels = [];
	const link = `/catalog/${props.parentCode}/${props.productId}`;
	return (
		<Fragment>
			<li
				className={css["offer"]}
				style={{ backgroundImage: `url('${props.image}')` }}
			>
				<Link to={link} className={css["rightLink"]}></Link>
				<div className={`${css["offer-data"]} ut-theme-color`}>
					<p className={css["offer-title"]}>{props.title}</p>
					<ul>{labels}</ul>
					<div className={css["offer-price"]}>
						<i className="ut-ico-sales"></i>{" "}
						<span>{util.currencyFormat(props.price)} </span>
					</div>
					<div className={css["offerButton"]}>
						<button
							onClick={props.cartHandler}
							className={`${style["filter-btn"]} ${css["offerBtn"]}  ut-theme-color`}
						>
							<ShoppingCartIcon size={12} /> <span>Добавить</span>
						</button>
					</div>
				</div>
			</li>
		</Fragment>
	);
};

export default Offer;
