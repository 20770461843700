import * as actionTypes from "../actionTypes";
import api from "../../../components/API/new/API";
import util from "../../../components/util/util";

export const addToCart = item => {
  return dispatch => {
    api.commerce.AddToCart(item).then(response => {
      dispatch({
        type: actionTypes.GET_CART,
        cart: response.data
      });
    });
  };
};

export const updateCartFields = data => {
  return dispatch => {
    dispatch({
      type: actionTypes.UPDATE_PENDING
    });
    api.commerce.UpdateCartFields(data).then(response => {
      dispatch({
        type: actionTypes.GET_CART,
        cart: response.data
      });
    });
  };
};

export const changeQuantity = (itemId, newQuantity, callback) => {
  const params = {
    itemId,
    quantity: newQuantity
  };

  return dispatch => {
    api.commerce
      .ChangeQuantity(params)
      .then(response => {
        dispatch({
          type: actionTypes.GET_CART,
          cart: response.data
        });
      })
      .finally(callback);
  };
};

export const removeFromCart = items => {
  return dispatch => {
    api.commerce.RemoveFromCart(items).then(response => {
      dispatch({
        type: actionTypes.GET_CART,
        cart: response.data
      });
    });
  };
};

export const getCart = () => {
  return dispatch => {
    api.commerce.GetCart().then(response => {
      dispatch({
        type: actionTypes.GET_CART,
        cart: response.data
      });
    });
  };
};
