import React, { useRef, useEffect, useState, Fragment } from "react";
import css from "./Cart.module.scss";
import PropTypes from "prop-types";
import util from "../util/util";

const CartItemField = props => {
    const textInput = useRef(null);
    if (props.focus && textInput.current) {
        textInput.current.focus();
    }

    return (
        <li className={css["product-param"]}>
            <span className={css["product-param-caption"]}>{props.name}</span>
            {props.edit ? (
                <Fragment>
                    <input
                        className={`${
                            !props.isChange
                                ? css["product-param-input--disabled"]
                                : css["product-param-input"]
                        }`}
                        placeholder={props.name}
                        value={props.value}
                        name={props.code}
                        onChange={props.changeHandler}
                        // disabled={props.isChange == false ? true : false}
                        ref={textInput}
                        // autoFocus={props.focus ? true : false}
                    />
                    {!props.isChange ? (
                        <div className={css["hackNotClick"]}></div>
                    ) : null}
                </Fragment>
            ) : (
                <span className={css["product-param-value"]}>
                    {props.value}
                </span>
            )}
        </li>
    );
};

CartItemField.defaultProps = {
    name: "",
    code: "",
    value: "",
    edit: false,
    changeHandler: e => {
        console.log(e.target.value);
    }
};

CartItemField.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    code: PropTypes.string.isRequired,
    edit: PropTypes.bool,
    changeHandler: PropTypes.func.isRequired
};

export default CartItemField;
