import React, { Component } from "react";
import { Form, Input, Button } from "../Interface/Interface";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import css from "./UserForm.module.scss";
import UserModel, { validationRules } from "../../model/User";

class RegisterForm extends Component {
    state = {
        lname: "",
        fname: "",
        phone: "",
        email: "",
        password: "",
        password_confirm: ""
    };

    handleFieldChange = e => {
        e.preventDefault();
        const name = e.target.getAttribute("name");
        const value = e.target.value;
        this.setState(state => {
            const newState = { ...state };
            newState[name] = value;
            return newState;
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.reg.error) {
            const error = this.props.reg.error;
            this.props.reset();
            alert(error);
        }
    }

    handleRegistration = () => {
        const fieldsToValidate = [
            [this.state.fname, validationRules.FIRSTNAME],
            [this.state.lname, validationRules.LASTNAME],
            [this.state.phone, validationRules.PHONE],
            [this.state.email, validationRules.EMAIL],
            [this.state.password, validationRules.PASSWORD],
            [
                this.state.password,
                validationRules.PASSWORD_MATCH,
                this.state.password_confirm
            ]
        ];
        const validationResult = UserModel.validateFields(fieldsToValidate);
        if (!validationResult.result) {
            return alert(validationResult.errors.join("\n"));
        }
        this.props.register({ ...this.state });
    };

    render = () => {
        return (
            <div className="ut-form__container">
                {this.props.isLogged ? (
                    <h3 className={css["title"]}>Вы авторизованы</h3>
                ) : (
                    <Wrapper>
                        <h3 className={css["title"]}>Регистрация</h3>
                        <Form>
                            <Input
                                onChange={this.handleFieldChange}
                                name="fname"
                                placeholder="Имя"
                                label="Введите Ваше имя"
                            />
                            <Input
                                onChange={this.handleFieldChange}
                                name="lname"
                                placeholder="Фамилия"
                                label="Введите Вашу фамилию"
                            />
                            <Input
                                onChange={this.handleFieldChange}
                                name="phone"
                                placeholder="Телефон"
                                label="Введите Ваш телефон"
                            />
                            <Input
                                onChange={this.handleFieldChange}
                                name="email"
                                placeholder="Email"
                                label="Введите Ваш Email"
                            />
                            <Input
                                name="password"
                                onChange={this.handleFieldChange}
                                placeholder="Пароль"
                                label="Введите Ваш пароль"
                                type="password"
                            />
                            <Input
                                name="password_confirm"
                                onChange={this.handleFieldChange}
                                placeholder="Повторите Пароль"
                                label="Введите Ваш пароль"
                                type="password"
                            />
                            <Button
                                onClick={e => {
                                    e.preventDefault();
                                    return this.handleRegistration();
                                }}
                                loading={this.props.reg.loading}
                            >
                                {this.props.title}
                            </Button>
                        </Form>
                    </Wrapper>
                )}
            </div>
        );
    };
}

const mapDispatchToProps = dispatch => ({
    register: formData => dispatch(actions.register(formData)),
    reset: () => dispatch(actions.regReset())
});
const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
    reg: state.user.regState
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
