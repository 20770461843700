import React, { useState } from "react";
import css from "./CategorySlider.module.scss";
import Label from "../Label/Label";
import LikeSwitch from "../LikeSwitch/LikeSwitch";
import util from "../util/util";
import { Link, withRouter } from "react-router-dom";
import { createObject } from "../../model/Favourites";
import img from "../../assets/image/dummy_small.jpg";
import ShareIcon from "@material-ui/icons/Share";
import {
	FacebookShareButton,
	TwitterShareButton,
	VKShareButton,
	WhatsappShareButton,
	FacebookIcon,
	WhatsappIcon,
	TwitterIcon,
	VKIcon,
} from "react-share";

const CategorySliderItem = (props) => {
	const [pickerDisplay, setPickerDisplay] = useState(true);

	let categories = [];
	if (props.item.category) {
		categories = props.item.category.split(",").map((item, index) => {
			return <li key={index}></li>;
		});
	}
	const labels = [];
	const _photo = util.getPropertySafe(
		props.item,
		"params.photos.0.small",
		img
	);
	if (props.item.params.avgcheck) {
		labels.push(
			<Label key={"avgcheck"} color="green">
				Средний чек {util.currencyFormat(props.item.params.avgcheck)}
			</Label>
		);
	}

	const itemData = createObject(
		props.item.id,
		props.item.name,
		_photo,
		props.category.code,
		props.item.code,
		util.getText(props.item.params.services_desc)
	);
	const _url = `${window.location.href}catalog/${props.category.code}/${props.item.code}`;
	const _title = props.item.name;
	const _image = util.getPropertySafe(
		props.item,
		"params.photos.0.small",
		util.dummyImage().small
	);

	const changeDisplay = (e) => {
		setPickerDisplay(!pickerDisplay);
	};

	return (
		<li
			className={css["block"]}
			style={{
				backgroundImage: `url(${props.item.params.photos[0].small})`,
			}}
		>
			<LikeSwitch itemData={itemData} />
			<ul
				className={
					pickerDisplay ? css["item-socials"] : css["displayBlock"]
				}
			>
				<li>
					<button onClick={changeDisplay}>
						<ShareIcon />
					</button>
				</li>
				<li>
					<VKShareButton url={_url} title={_title} image={_image}>
						<VKIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</VKShareButton>
				</li>
				<li>
					<WhatsappShareButton
						url={_url}
						title={_title}
						image={_image}
						separator={" "}
					>
						<WhatsappIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</WhatsappShareButton>
				</li>
				{/* <li>
          <InstapaperShareButton
            url={_url}
            title={_title}
            description="Make your uniqtrip with us"
            image={_image}
          >
            <i className="ut-icon-instagram1"></i>
          </InstapaperShareButton>
        </li> */}

				<li>
					<TwitterShareButton
						url={_url}
						quote={_title}
						hashtag={"#UniqTrip"}
					>
						<TwitterIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</TwitterShareButton>
				</li>
				<li>
					<FacebookShareButton
						className={css["shareBtn"]}
						url={_url}
						quote={_title}
						hashtag={"#UniqTrip"}
						// image={_image}
					>
						<FacebookIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</FacebookShareButton>
				</li>
			</ul>
			<Link
				to={`/catalog/${props.category.code}/${props.item.code}`}
				className={css["block-img-link"]}
			></Link>
			<div className={css["block-data"]}>
				<ul className={css["block-labels"]}>{labels}</ul>
				{props.item.params.price ? (
					<div
						className={util.join(
							"ut-theme-color",
							css["block-price"]
						)}
					>
						<span className={css["prefixPrice"]}>от</span>{" "}
						<span className={css["averagePrice"]}>
							{util.currencyFormat(props.item.params.price)}
						</span>
					</div>
				) : (
					""
				)}
				<div className={css["block-container"]}>
					<Link
						to={`/catalog/${props.category.code}/${props.item.code}`}
					>
						<h6 className={css["block-title"]}>
							{util.entitiyDecoder(props.item.name)}
						</h6>
						<ul className={css["block-tags"]}>{categories}</ul>
						<ul className={css["block-features"]}>
							{/* <li>использование локальных продуктов</li>
                            <li>раки в различной варке или жарке</li>
                            <li>доставка по территории Горки Город </li> */}
							{util.getText(props.item.params.services_desc, 160)}
						</ul>
					</Link>
					<a className={css["block-review"]}>
						{/* <img src="media/profile1.jpg" alt="" />
                        <p>Очень атмосферно, есть парковка для посетителей, очень вкусные огуречные ...</p> */}
					</a>
				</div>
				<div className={css["block-buttons"]}>
					{/* <button className={`${css['block-btn']} ${css['block-btn-icon']} ut-theme-color`} >
                                <IconCart />
                            </button> */}
					{}
					<Link
						to={`/catalog/${props.category.code}/${props.item.code}`}
						className={`${css["block-btn"]} ut-theme-color`}
					>
						<span>
							{props.item.params.price || props.category.id == 7
								? "Забронировать"
								: "Подробнее"}
						</span>
					</Link>
					{/* <button  class="ut-blocks__block-btn ut-theme-color">
                                <span>меню</span>
                            </button> */}
				</div>
			</div>
		</li>
	);
};

export default withRouter(CategorySliderItem);
