import React, { useState, Fragment } from "react";
import css from "./CustomerData.module.scss";
import util from "../util/util";
import withOverlay from "../../hoc/Overlay/Overlay";
import { Button } from "../Interface/Interface";
import UserModel, { validationRules } from "../../model/User";

const CustomerData = props => {
    const user = props.user.isLogged ? props.user.profile : null;
    const [createAccountCb, toggleCbState] = useState(true);
    const [isLoading, toggleLoading] = useState(false);

    const initialFormData = {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        password: "",
        password_confirm: ""
    };
    const [formData, updateForm] = useState(initialFormData);

    const changeFieldHanlder = (key, value) => {
        const newFormData = { ...formData };
        newFormData[key] = value;
        updateForm(newFormData);
    };

    const handleSubmit = () => {
        const fieldsToValidate = [
            [formData.fname, validationRules.FIRSTNAME],
            [formData.lname, validationRules.LASTNAME],
            [formData.email, validationRules.EMAIL],
            [formData.phone, validationRules.PHONE]
        ];
        if (createAccountCb) {
            fieldsToValidate.push(
                [formData.password, validationRules.PASSWORD],
                [
                    formData.password,
                    validationRules.PASSWORD_MATCH,
                    formData.password_confirm
                ]
            );
        }

        const validationResult = UserModel.validateFields(fieldsToValidate);
        if (!validationResult.result) {
            return alert(validationResult.errors.join("\n"));
        }

        props.saveHandler(
            {
                ...formData,
                createAccount: createAccountCb,
                feedback: true
            },
            toggleLoading
        );
    };
    return (
        <div className={css["form"]}>
            <h5 className={util.join("ut-section__title", css["form-title"])}>
                Ваши данные
            </h5>
            <p className={css.text}>Введите информацию о себе</p>
            <div className={css.fieldset}>
                <input
                    className={util.join(css.input, "ut-form__input")}
                    placeholder="Имя"
                    name="fname"
                    value={formData.fname}
                    onChange={e =>
                        changeFieldHanlder(e.target.name, e.target.value)
                    }
                />
                <input
                    className={util.join(css.input, "ut-form__input")}
                    placeholder="Фамилия"
                    name="lname"
                    value={formData.lname}
                    onChange={e =>
                        changeFieldHanlder(e.target.name, e.target.value)
                    }
                />
                <input
                    className={util.join(css.input, "ut-form__input")}
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={e =>
                        changeFieldHanlder(e.target.name, e.target.value)
                    }
                />
                <input
                    className={util.join(css.input, "ut-form__input")}
                    placeholder="Телефон"
                    name="phone"
                    value={formData.phone}
                    onChange={e =>
                        changeFieldHanlder(e.target.name, e.target.value)
                    }
                />

                {createAccountCb ? (
                    <Fragment>
                        <input
                            className={util.join(css.input, "ut-form__input")}
                            placeholder="Пароль"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={e =>
                                changeFieldHanlder(
                                    e.target.name,
                                    e.target.value
                                )
                            }
                        />
                        <input
                            className={util.join(css.input, "ut-form__input")}
                            placeholder="Повторите пароль"
                            type="password"
                            name="password_confirm"
                            value={formData.password_confirm}
                            onChange={e =>
                                changeFieldHanlder(
                                    e.target.name,
                                    e.target.value
                                )
                            }
                        />
                    </Fragment>
                ) : null}
            </div>
            <div className={css["cb-group"]}>
                <input
                    id="account_f"
                    checked={createAccountCb}
                    name="createAccount"
                    value={true}
                    onChange={() => toggleCbState(!createAccountCb)}
                    type="checkbox"
                />
                <label htmlFor="account_f" className={css["text"]}>
                    Создать учетную запись
                </label>
            </div>
            <div className={css["cb-group"]}>
                <input
                    id="policy_f"
                    defaultChecked={true}
                    name="account"
                    type="checkbox"
                />
                <label htmlFor="policy_f" className={css["text"]}>
                    Согласен получать информационную рассылку, прочитал и принял
                    условия обслуживания, ознакомлен с политикой обработки
                    персональных данных
                </label>
            </div>

            <Button onClick={handleSubmit} loading={isLoading}>
                <span>ОПЛАТИТЬ</span>
            </Button>
        </div>
    );
};

export default withOverlay(CustomerData);
