import React, { Component } from "react";
import css from "./CategorySlider.module.scss";
import CategorySliderItem from "./_CategorySliderItem";
import { Link } from "react-router-dom";
import apiDev from "../API/API.dev";
import util from "../util/util";
import withLoading from "../../hoc/Loading/Loading";

/**
 * Mobile Detect
 */

const gridSize = () => {
  const SMALL = 399;
  const MED = 767;
  const GRID = {
    small: 1,
    med: 2,
    large: 3
  };
  return window.innerWidth <= SMALL
    ? GRID.small
    : window.innerWidth <= MED
    ? GRID.med
    : GRID.large;
};

class CategorySlider extends Component {
  // Drag & Drop
  dragStartX = 0;
  left = 0;
  dragged = false;

  // FPS Limit
  startTime;
  fpsInterval = 5000 / 60;

  state = {
    items: [],
    visibleItems: [],
    offset: 0,
    end: false,
    loadSize: gridSize(),
    left: 0
  };

  componentDidMount = () => {
    this.loadMore();
    window.addEventListener("resize", () => {
      const loadSize = gridSize();
      this.setState(state => ({
        ...state,
        loadSize
      }));
    });
    // window.addEventListener("mouseup", this.onDragEndMouse);
    window.addEventListener("touchend", this.onDragEndTouch);
  };

  componentWillUnmount() {
    // window.removeEventListener("mouseup", this.onDragEndMouse);
    window.removeEventListener("touchend", this.onDragEndTouch);
  }

  loadMore = cb => {
    const params = {
      categoryId: this.props.category.id,
      offset: this.state.items.length
    };
    if (this.props.subCategory) {
      params.subcategoryId = this.props.subCategory.id;
    }
    this.props.loading(true);
    apiDev("catalog/GetPartnersByCategory", params).then(response => {
      this.props.loading(false);
      if (response.data && Array.isArray(response.data)) {
        const items = this.state.items.concat(response.data);

        this.setState(
          state => ({
            ...state,
            //end: (response.data.length < 10),
            items
          }),
          cb
        );
      } else {
        this.setState(state => ({
          ...state,
          end: true
        }));
      }
    });
  };

  scrollLeft = () => {
    const loadSize = this.state.loadSize;
    let offset = this.state.offset;
    if (offset == 0) return;
    const newOffset = offset - loadSize < 0 ? 0 : offset - loadSize;
    offset = newOffset + loadSize;
    this.setState(state => ({
      ...state,
      offset: newOffset,
      end: false
    }));
  };

  scrollRight = () => {
    const loadSize = this.state.loadSize;
    if (this.state.offset + loadSize >= this.state.items.length) {
      this.loadMore(() => {
        this.setState(state => ({
          ...state,
          offset: state.offset + loadSize
        }));
      });
    } else {
      const maxOffset = this.state.items.length - loadSize;
      const newOffset = this.state.offset + loadSize;
      this.setState(state => ({
        ...state,
        offset: newOffset < maxOffset ? newOffset : maxOffset
      }));
    }
  };

  //swipe logic start

  // onDragStartMouse = evt => {
  //   console.log(evt.clientX);

  //   this.onDragStart(evt.clientX);
  //   window.addEventListener("mousemove", this.onMouseMove);
  // };

  onDragStartTouch = evt => {
    const touch = evt.targetTouches[0];
    this.onDragStart(touch.clientX);
    window.addEventListener("touchmove", this.onTouchMove);
  };

  onDragStart = clientX => {
    this.dragged = true;
    this.dragStartX = clientX;
    this.startTime = Date.now();
    // this.updatePosition();
  };

  onDragEndMouse = evt => {
    window.removeEventListener("mousemove", this.onMouseMove);
    this.onDragEnd();
  };

  onDragEndTouch = evt => {
    const touch = evt.changedTouches[0];
    // console.log(evt);

    this.onDragEnd(touch.clientX);
    window.removeEventListener("touchmove", this.onTouchMove);
    this.onDragEnd();
  };

  onDragEnd = pass => {
    if (this.dragged) {
      this.dragged = false;
      if (this.left <= -100) {
        this.scrollRight();
      } else {
        if (this.left >= 100) {
          this.scrollLeft();
        }
      }
      this.left = 0;
    }
  };

  onMouseMove = evt => {
    const left = evt.clientX - this.dragStartX;
    console.log(left);

    if (left < 0) {
      this.left = left;
    }
  };

  onTouchMove = evt => {
    const touch = evt.targetTouches[0];

    const left = touch.clientX - this.dragStartX;

    this.left = left;
    // }
  };

  //swipe logic end

  render = () => {
    const items = this.state.items
      .slice(this.state.offset, this.state.offset + this.state.loadSize)
      .map((item, index) => (
        <CategorySliderItem
          idx={index}
          key={index}
          item={item}
          category={this.props.category}
        />
      ));
    if (items.length == 0) return null;
    return (
      <section>
        <div className="ut-section__container ut-theme-color">
          <Link
            to={`/category/${this.props.category.code}`}
            className="ut-section__title"
          >
            {this.props.subCategory
              ? this.props.subCategory.name
              : this.props.category.name}
          </Link>
          <button
            onClick={this.scrollLeft}
            className={`${css["controls"]} ${css["controls-left"]}`}
            disabled={this.state.offset <= 0}
          >
            ❬
          </button>
          <button
            onClick={this.scrollRight}
            className={`${css["controls"]} ${css["controls-right"]}`}
            disabled={this.state.end}
          >
            ❭
          </button>
          <ul onTouchStart={this.onDragStartTouch} className={css["container"]}>
            {items}
          </ul>
        </div>
      </section>
    );
  };
}

export default withLoading(CategorySlider);
