import util from "../components/util/util";

class Orders {
  constructor(orders, trigger) {
    this.orders = orders;
    this.trigger = trigger;
  }
  sortId = flag => {
    let idSorted = [];
    if (flag) {
      idSorted = this.orders.sort((a, b) => {
        return a.orderId - b.orderId;
      });
    } else {
      idSorted = this.orders.sort((a, b) => {
        return b.orderId - a.orderId;
      });
    }
    return idSorted;
  };
  sortPrice = flag => {
    let priceSorted = [];
    if (flag) {
      priceSorted = this.orders.sort((a, b) => {
        return a.orderPrice - b.orderPrice;
      });
    } else {
      priceSorted = this.orders.sort((a, b) => {
        return b.orderPrice - a.orderPrice;
      });
    }
    return priceSorted;
  };

  compare = (a, b) => {
    const bandA = a.statusCode;
    const bandB = b.statusCode;

    if (this.trigger) {
      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
    } else {
      let comparison = 0;
      if (bandB > bandA) {
        comparison = 1;
      } else if (bandB < bandA) {
        comparison = -1;
      }
      return comparison;
    }
  };

  sortByStatusCode = () => {
    let byCode = this.orders.sort(this.compare);
    return byCode;
  };
  formatingDate = e => {
    const orderDate = e;
    const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    const newOrderDate =
      e != null ? new Date(orderDate.replace(pattern, "$3-$2-$1")) : null;
    return newOrderDate;
  };
  sortByDate = flag => {
    let sortedDate = [];
    sortedDate = this.orders.sort((a, b) => {
      const newOrderDateA = this.formatingDate(a.orderDate);
      const newOrderDateB = this.formatingDate(b.orderDate);
      let test = util.moment(newOrderDateA);
      let testb = util.moment(newOrderDateB);
      if (flag) {
        return test - testb;
      } else {
        return testb - test;
      }
    });

    return sortedDate;
  };
  sortByPayDate = flag => {
    let sortedDate = this.orders.sort((a, b) => {
      const newOrderDateA = this.formatingDate(a.orderPayDate);
      const newOrderDateB = this.formatingDate(b.orderPayDate);
      let test = util.moment(newOrderDateA);
      let testb = util.moment(newOrderDateB);
      if (flag) {
        return test - testb;
      } else {
        return testb - test;
      }
    });

    return sortedDate;
  };
}
export default Orders;
