import React, { Component } from "react";
import classes from "./Header.module.scss";
import style from "../Footer/Footer.module.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import util from "../util/util";
import profileImg from "../../assets/image/profile.svg";
import img from "../../assets/image/dummy_small.jpg";
import api_config from "../API/API.config";
import Wrapper from "../../hoc/Wrapper/Wrapper";
import UserForm from "../UserForm/UserForm";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import * as actions from "../../store/actions/index";
import * as routes from "../util/routes";
import api from "../API/new/API";
import apiDev from "../API/API.dev";
// emergency
import { EmergencyBanner } from "../Interface/Interface";

class Header extends Component {
	state = {
		formVisible: false,
		panelVisible: false,
		emergencyBannerVisible: false,
		emergencyText:
			"Ввиду нынешней ситуации, связанной с коронавирусом, мы приостанавливаем бронирование гостиниц и услуг.",
	};

	componentDidMount() {
		this.props.login();
		this.props.getCart();
		this.props.getCategories();
		this.props.getCountriesList();
		this.props.getBanners();
		apiDev("settings/checkEmergencyStatus").then((resp) => {
			if (typeof resp.data == "object") {
				if (Object.entries(resp.data).length === 0) {
					this.setState({
						...this.state,
						emergencyBannerVisible: false,
					});
				} else {
					this.setState({
						...this.state,
						emergencyBannerVisible: true,
						emergencyText: resp.data.emergencyMessage,
					});
				}
			}
		});
	}

	formToggle = () => {
		this.setState((state) => ({
			...state,
			formVisible: !state.formVisible,
		}));
	};

	panelToggle = () => {
		this.setState((state) => ({
			...state,
			panelVisible: !state.panelVisible,
		}));
	};
	setCategory = (category) => {
		if (category) {
			if (!this.props.category) {
				this.panelToggle();
				return this.props.setCategory(category);
			} else if (category.id != this.props.category.id) {
				this.panelToggle();
				return this.props.setCategory(category);
			} else {
				this.panelToggle();
				return this.props.setCategory(null);
			}
		}
		this.panelToggle();
		return this.props.setCategory(null);
	};
	quantityProducts = () => {
		let quantity = 0;

		this.props.user.cart.partners.forEach((element) => {
			quantity += element.quantity;
		});
		return quantity;
	};
	hideHandlerBanner = () => {
		this.setState((state) => ({
			...state,
			emergencyBannerVisible: false,
		}));
	};

	render() {
		this.quantityProducts();
		const form = this.state.formVisible ? (
			<UserForm hideHandler={this.formToggle} />
		) : null;

		const profileIcon = this.props.isLogged ? (
			<Link to={routes.PROFILE_PREFIX}>
				<img
					src={util.getPropertySafe(
						this.props.user,
						"profile.photo",
						img
					)}
				/>
			</Link>
		) : (
			<button className={classes["btnInn"]} onClick={this.formToggle}>
				<span>вход</span>
			</button>
			// <img src={profileImg} onClick={this.formToggle} />
		);

		const panelCategories = this.props.catalog.categories.map(
			(category, index) => {
				const checked =
					Boolean(this.props.category) &&
					this.props.category.id == category.id;
				const link = routes.CATEGORY_PREFIX + category.code;
				return (
					<Link
						key={index}
						className={util.join(
							"ut-theme-color",
							classes["panel-category"],
							checked && classes["panel-category--on"]
						)}
						to={link}
					>
						<div
							className={classes["panel-icon"]}
							style={{
								backgroundImage:
									"url(" +
									util.prependHostname(category.icon) +
									")",
							}}
						/>
						<li key={index}>
							<span>{category.name}</span>
						</li>
					</Link>
				);
			}
		);

		const panel = (
			<div
				className={util.join(
					classes["panel"],
					this.state.panelVisible && classes["panel--on"]
				)}
				onClick={(e) => {
					if (e.target.classList.contains(classes["panel"])) {
						this.panelToggle();
					}
				}}
			>
				<div className={classes["panel-content"]}>
					<ul className="ut-theme-color">{panelCategories}</ul>
				</div>
			</div>
		);

		const dayOfTheYear = util.moment().format("DDD");
		return (
			<Wrapper>
				{panel}
				{form}
				<div
					className={`${
						this.state.emergencyBannerVisible
							? classes["wrapper--open"]
							: classes["wrapper"]
					}`}
				>
					<div className={classes["wrapper-emergency"]}>
						<EmergencyBanner
							text={this.state.emergencyText}
							toggle={this.state.emergencyBannerVisible}
							hideHandler={this.hideHandlerBanner}
						/>
						<header className={classes["header"]}>
							<div className={classes["logo"]}>
								<span
									onClick={this.panelToggle}
									className={[
										classes["logo-link"],
										"ut-theme-color",
									].join(" ")}
								>
									☰
								</span>
								&nbsp;
								<a
									href="/"
									className={[
										classes["logo-link"],
										"ut-theme-color",
										(dayOfTheYear > 360 ||
											dayOfTheYear < 14) &&
											classes["logo--ny"],
									].join(" ")}
								>
									<span>Uniq</span>Trip
								</a>
							</div>
							<nav className={classes["menu"]}>
								<ul>
									{/*    <li className="v-desktop">
                  <a
                    className={[
                      classes["menu-link"],
                      "ut-theme-color",
                      "ut-brand"
                    ].join(" ")}
                    href="#club"
                  >
                    Uniq<span>Trip</span>Club
                  </a>
                </li> */}
									<li className={classes["header-app"]}>
										<span>
											Наше приложение в AppStore и Google
											Play
										</span>
										<a href="https://play.google.com/store/apps/details?id=com.uniqtrip.uniqtrip">
											<AndroidIcon
												className={"ut-theme-color"}
											/>
										</a>
										<a href="https://apps.apple.com/us/app/uniqtrip/id1499071482?l=ru&ls=1">
											<AppleIcon
												className={"ut-theme-color"}
											/>
										</a>
									</li>
									<li className="v-desktop">
										<a
											className={[
												classes["menu-link"],
												classes["menu-link-simple"],
											].join(" ")}
											href="https://uniqtrip.ru"
											target="_blank"
										>
											Для Бизнеса
										</a>
									</li>
									{/*                                 <li className="v-desktop">
                                    <a className={[classes["menu-link"],classes["menu-link-simple"]].join(' ')} href="#deferred">Рассрочка и Кредит</a>
                                </li> */}
									<li className="v-desktop">
										<a
											className={[
												classes["menu-link"],
												classes["menu-link-black"],
											].join(" ")}
											href="tel:+78622777042"
										>
											+7 862 277 70 42
										</a>
									</li>
									<li>
										<Link
											to="/cart"
											className={[
												classes["menu-link"],
												classes["menu-icon"],
												"ut-theme-color",
											].join(" ")}
										>
											<ShoppingCartIcon size={24} />
											{this.props.user.cart.positions >
											0 ? (
												<sub>
													<span>
														{this.quantityProducts()}
													</span>
												</sub>
											) : null}
										</Link>
									</li>
									<li
										className={[
											classes["profile"],
											"ut-theme-color",
										].join(" ")}
									>
										{profileIcon}
									</li>
								</ul>
							</nav>
						</header>
					</div>
				</div>
			</Wrapper>
		);
	}
}

const mapStateToProps = (state) => ({
	isLogged: state.user.isLogged,
	user: state.user,
	catalog: state.catalog,
	category: state.user.customerData.category,
});

const mapDispatchToProps = (dispatch) => ({
	login: () => dispatch(actions.login()),
	getCart: () => dispatch(actions.getCart()),
	getCategories: () => dispatch(actions.getCategories()),
	getBanners: () => dispatch(actions.getBanners()),
	getCountriesList: () => dispatch(actions.getCountriesList()),
	setTerm: (term) => dispatch(actions.setTerm(term)),
	setCategory: (category) => dispatch(actions.setCategory(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
