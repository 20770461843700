import React from "react";
import css from "./OrderTotals.module.scss";
import util from "../util/util";

const OrderTotals = props => {
  const items = props.partners.map((partner, partnerIndex) => (
    <li className={css.item} key={partnerIndex}>
      <p className={css["item-name"]}>{partner.name}</p>
      <p className={css["item-price"]}>{util.currencyFormat(partner.total)}</p>
    </li>
  ));

  return props.total > 0 ? (
    <div className={util.join(css.container, "ut-theme-color")}>
      <h5 className={css.title}>Cписок приключений готов к оплате</h5>
      <ul>{items}</ul>
      <div className={css["total"]}>
        <p className={css["total-caption"]}>Итого</p>
        <p className={css["total-price"]}>
          {util.currencyFormat(props.total)}
          <span className={css["total-hint"]}>включая налоги и сборы</span>
        </p>
      </div>
      {props.emergency ? (
        <p className={css["emergencyText"]}>{props.emergencyText}</p>
      ) : (
        <button
          data-for="emergency"
          onClick={props.payHandler}
          className="ut-form__button-inverse ut-form__button-wide"
          // disabled={props.emergency}
        >
          {" "}
          <span>Оплатить</span>{" "}
        </button>
      )}
    </div>
  ) : null;
};

export default OrderTotals;
