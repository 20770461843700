import React, { useState, useEffect } from "react";
import withLoading from "../../hoc/Loading/Loading";
import css from "./Banner.module.scss";
import util from "../util/util";

const Banner = props => {
  return (
    <section>
      <div
        className={util.join(
          "ut-section__container",
          "ut-theme-color",
          css["containerBanner"]
        )}
      >
        <a
          href={props.actual.url}
          target={props.actual.target}
          className={util.join("ut-banner", css["mobileBunner"])}
          style={{ backgroundImage: `url(${props.actual.imageUrl})` }}
        >
          <div className={util.join(css["bannerData"])}>
            <a
              href={props.actual.url}
              target={props.actual.target}
              className="ut-banner__title"
            >
              {props.actual.header}
            </a>
            <a
              href={props.actual.url}
              target={props.actual.target}
              className="ut-banner__slogan"
            >
              {props.actual.price}
            </a>
            <a
              href={props.actual.url}
              target={props.actual.target}
              className={css["bannerText"]}
            >
              {props.actual.description}
            </a>
            {/* <div className="ut-banner__buttons"> */}
            <a
              href={props.actual.url}
              target={props.actual.target}
              className={util.join("ut-theme-color", css["bannerButton"])}
            >
              <span>{props.actual.button}</span>
            </a>
            {/* </div> */}
          </div>
        </a>
      </div>
    </section>
  );
};

export default withLoading(Banner);
