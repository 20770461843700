import React, { Fragment } from "react";
import Header from "./../components/Header/Header";
import Footer from "./../components/Footer/Footer";

import "./LayoutCommon.scss";

const LayoutCommon = props => (
    <Fragment>
        <Header />
        <div className="ut-main-container">{props.children}</div>
        <Footer />
    </Fragment>
);

export default LayoutCommon;
