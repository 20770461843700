import React from "react";
import css from "./Hero.module.scss";
import Filter from "./_MainPanelFilter";
import PropTypes from "prop-types";

const MainPanel = props => {
    return (
        <div
            className={css["main"]}
            style={{
                backgroundImage: `url(${props.backgroundImage})`
            }}
        >
            <Filter />
        </div>
    );
};

MainPanel.propTypes = {
    backgroundImage: PropTypes.string
};

MainPanel.defaultProps = {
    backgroundImage: ""
};

export default MainPanel;
