/**
 *
 * Refactored version
 *
 * General cart product layout
 *
 */

import React, { useState } from "react";
import css from "./Cart.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import ShareWidget from "../Share/ShareWidget";
import CartItemProduct from "./_CartItemProduct_Other";
import UserModel from "../../model/User";
import QuantityInput from "./_QuantityInput";
import util from "../util/util";

const CartItem = (props) => {
	const itemsSummary = props.items.map((item, index) => (
		<li key={index}>
			{item.name} x{" "}
			<QuantityInput
				quantityHandler={(quantity, callback) =>
					props.changeQuantity(item.id, quantity, callback)
				}
			>
				{item.quantity}
			</QuantityInput>
		</li>
	));

	const removeHandler = () => {
		const itemsToRemove = props.items.map((item) => item.id);
		props.removeFromCart(itemsToRemove);
	};

	const [expanded, toggleExpanded] = useState(false);

	// Here we use complex key to ensure
	// that input fileds are updated properly
	// during saving and remove operations
	const now = Date.now();
	const products = props.items.map((product, productIndex) => {
		const updateHandler = (fields) => {
			const fieldsToValidate = Object.values(fields)
				.filter((field) => field.edit && field.value.length > 0)
				.map((field) => [field.value, field.code]);

			const validationResult = UserModel.validateFields(fieldsToValidate);
			if (!validationResult.result) {
				return alert(validationResult.errors.join("\n"));
			}
			const updatedItem = {
				email: fields.email ? fields.email.value : null,
				phone: fields.phone ? fields.phone.value : null,
				Fname: fields.Fname ? fields.Fname.value : null,
				Lname: fields.Lname ? fields.Lname.value : null,
				item: {
					...product,
					props: [...product.params],
					categoryId: props.categoryId,
					productId: product.productId,
				},
			};
			return props.updateCartFields({ payload: [updatedItem] });
		};

		return (
			<CartItemProduct
				key={`${productIndex}_${product.id}_${now}`}
				{...product}
				updateHandler={updateHandler}
				removeHandler={removeHandler}
				loading={props.updateState.loading}
			/>
		);
	});

	let toggleText = expanded ? "Данные услуги" : "Подробнее";

	return (
		<div className={css["item"]}>
			<div className={css["item-main"]}>
				<ul className={util.join("ut-theme-color", css["item-icons"])}>
					<li
						className={`${css["item-icon"]} ${css["item-red"]}`}
						onClick={removeHandler}
					>
						<ClearIcon />
					</li>
					<li>
						<ShareWidget
							items={props.items}
							categoryId={props.categoryId}
							productId={props.productId}
						/>
					</li>
				</ul>

				<img src={props.image} className={css["item-image"]} />
				<div className={css["item-data"]}>
					<h3 className={css["item-title"]}>{props.name}</h3>
					<ul className={css["item-summary"]}>{itemsSummary}</ul>
					<div className={css["item-price"]}>
						Цена {util.currencyFormat(props.total)}
						<span
							className={util.join(
								css["item-toggle"],
								"ut-theme-color"
							)}
						>
							<input type="checkbox" defaultChecked={expanded} />
							<label
								className={util.join(
									expanded
										? css["item-toggle-activeLabel"]
										: ""
								)}
								onClick={() => toggleExpanded(!expanded)}
							>
								{toggleText}
							</label>
						</span>
					</div>
				</div>
			</div>
			<div
				className={
					expanded
						? css["item-details--expanded"]
						: css["item-details"]
				}
			>
				{products}
			</div>
		</div>
	);
};
const mapStateToProps = (state) => ({
	favourites: state.user.favourites,
	updateState: state.user.updateState,
});

const mapDispatchToProps = (dispatch) => ({
	addFavourite: (itemData) => dispatch(actions.addFavourite(itemData)),
	removeFavourite: (itemData) => dispatch(actions.removeFavourite(itemData)),
	removeFromCart: (items) => dispatch(actions.removeFromCart(items)),
	updateCartFields: (data) => dispatch(actions.updateCartFields(data)),
	changeQuantity: (positionId, newQuantity, callback) =>
		dispatch(actions.changeQuantity(positionId, newQuantity, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
