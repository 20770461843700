import util from "../../components/util/util";

const config = {
	methodName: "GetFilterDataByPartnerCode",
	isTest: false,
	isDummy: false,
	method: "POST",
	dummyData: {
		maxAdults: 4,
		maxChildren: 2,
		minDate: util.moment("10.03.2020", "DD.MM.YYYY"),
		maxDate: util.moment("10.05.2020", "DD.MM.YYYY"),
	},

	prepareRequest: (r) => r,

	prepareResponse: (r) => {
		if (!r.error) {
			const responseMinDate = util.moment(r.minDate, "DD.MM.YYYY");
			const now = util.moment();
			const minDate = responseMinDate.isBefore(now)
				? now
				: responseMinDate;

			return {
				minDate,
				maxDate: util.moment(r.maxDate, "DD.MM.YYYY"),
				maxAdults: parseInt(r.maxAdults),
				maxChildren: parseInt(r.maxChildren),
			};
		}
		return r;
	},

	handleError: (e) => e,
};

export default config;
