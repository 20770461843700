import * as actionTypes from "../actions/actionTypes";
import util from "../../components/util/util";
import favouritesModel from "../../model/Favourites";

const InitialState = {
    isLogged: false,
    location: {
        country: "Россия",
        city: "Сочи"
    },

    authState: {
        loading: false,
        error: false
    },
    regState: {
        loading: false,
        error: false
    },
    updateState: {
        loading: false,
        error: false
    },
    profile: {},
    cart: {
        partners: [],
        total: 0,
        positions: 0,
        shareData: ""
    },
    customerData: {
        filter: {
            dateIn: util.moment().add(0, "day"),
            dateOut: util.moment().add(1, "day"),
            time: "10:00",
            adults: 2,
            children: 0,
            subCategory: null
        },
        searchTerm: "",
        category: null,
        sort: "ASC"
    },
    favourites: favouritesModel.get()
};

/**
 *
 * Location
 *
 */
const setLocation = (state, action) => {
    return {
        ...state,
        location: {
            ...action.location
        }
    };
};

const getCart = (state, action) => {
    return {
        ...state,
        updateState: {
            loading: false,
            error: false
        },
        cart: action.cart
    };
};

const setUser = (state, action) => {
    return {
        ...state,
        isLogged: true,
        profile: action.user
    };
};

const removeFavourite = (state, action) => {
    const favourites = [...state.favourites].filter(
        e => e.id !== action.item.id
    );

    return {
        ...state,
        favourites
    };
};

const addFavourite = (state, action) => {
    const favourites = favouritesModel.merge([
        ...favouritesModel.get(),
        action.item
    ]);

    return {
        ...state,
        favourites
    };
};

const setFavourites = (state, action) => {
    const favourites = favouritesModel.merge([
        ...favouritesModel.get(),
        ...action.data
    ]);

    return {
        ...state,
        favourites
    };
};

/**
 *
 * Authentication
 *
 */

const authPending = (state, action) => {
    return {
        ...state,
        authState: {
            loading: true,
            error: false
        }
    };
};

const authReset = (state, action) => {
    return {
        ...state,
        authState: {
            loading: false,
            error: false
        }
    };
};

const authSuccess = (state, action) => {
    const favourites = favouritesModel.merge([
        ...favouritesModel.get(),
        ...action.user.favourites
    ]);
    return {
        ...state,
        isLogged: true,
        authState: {
            loading: false,
            error: false
        },
        profile: {
            ...action.user
        },
        favourites
    };
};

const authNotPerformed = state => {
    return {
        ...state,
        isLogged: false,
        authState: {
            loading: false,
            error: false
        },
        profile: {},
        favourites: favouritesModel.get()
    };
};

const authFailed = (state, action) => {
    return {
        ...state,
        authState: {
            loading: false,
            error: action.error
        }
    };
};
/**
 *
 * Profile Update
 *
 */

const updatePending = (state, action) => {
    return {
        ...state,
        updateState: {
            loading: true,
            error: false
        }
    };
};

const updateReset = (state, action) => {
    return {
        ...state,
        updateState: {
            loading: false,
            error: false
        }
    };
};

const updateSuccess = (state, action) => {
    return {
        ...state,
        isLogged: true,
        updateState: {
            loading: false,
            error: false
        },
        profile: {
            ...action.user
        }
    };
};

const updateNotPerformed = state => {
    return {
        ...state,
        isLogged: false,
        updateState: {
            loading: false,
            error: false
        },
        profile: {}
    };
};

const updateFailed = (state, action) => {
    return {
        ...state,
        updateState: {
            loading: false,
            error: action.error
        }
    };
};

const updatePhoto = (state, action) => {
    return {
        ...state,
        profile: {
            ...state.profile,
            photo: action.photo
        }
    };
};

/**
 *
 * Registration
 *
 */

const regPending = (state, action) => {
    return {
        ...state,
        regState: {
            loading: true,
            error: false
        }
    };
};

const regReset = (state, action) => {
    return {
        ...state,
        regState: {
            loading: false,
            error: false
        }
    };
};

const regSuccess = (state, action) => {
    return {
        ...state,
        isLogged: true,
        regState: {
            loading: false,
            error: false
        },
        profile: {
            ...action.user
        }
    };
};

const regFailed = (state, action) => {
    return {
        ...state,
        regState: {
            loading: false,
            error: action.error
        }
    };
};

/**
 *
 * Customer Data
 *
 */

const setFilter = (state, action) => {
    return {
        ...state,
        customerData: {
            ...state.customerData,
            filter: {
                ...state.customerData.filter,
                ...action.filter
            }
        }
    };
};

const setCategory = (state, action) => {
    return {
        ...state,
        customerData: {
            ...state.customerData,
            category: action.category ? action.category : null
        }
    };
};

const setTerm = (state, action) => {
    return {
        ...state,
        customerData: {
            ...state.customerData,
            searchTerm: action.term ? action.term : ""
        }
    };
};

const setSort = (state, action) => {
    return {
        ...state,
        customerData: {
            ...state.customerData,
            sort: action.sort
        }
    };
};

/**
 *
 * Reducer
 *
 */

const reducer = (state = InitialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CART:
            return getCart(state, action);

        case actionTypes.AUTH_PENDING:
            return authPending(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_RESET:
            return authReset(state, action);
        case actionTypes.AUTH_FAILED:
            return authFailed(state, action);
        case actionTypes.AUTH_NOT_PERFORMED:
            return authNotPerformed(state, action);

        case actionTypes.UPDATE_PENDING:
            return updatePending(state, action);
        case actionTypes.UPDATE_SUCCESS:
            return updateSuccess(state, action);
        case actionTypes.UPDATE_RESET:
            return updateReset(state, action);
        case actionTypes.UPDATE_FAILED:
            return updateFailed(state, action);
        case actionTypes.UPDATE_NOT_PERFORMED:
            return updateNotPerformed(state, action);
        case actionTypes.UPLOAD_PHOTO_SUCCESS:
            return updatePhoto(state, action);

        case actionTypes.REG_PENDING:
            return regPending(state, action);
        case actionTypes.REG_SUCCESS:
            return regSuccess(state, action);
        case actionTypes.REG_RESET:
            return regReset(state, action);
        case actionTypes.REG_FAILED:
            return regFailed(state, action);

        case actionTypes.SET_USER:
            return setUser(state, action);
        case actionTypes.ADD_FAVOURITE:
            return addFavourite(state, action);
        case actionTypes.REMOVE_FAVOURITE:
            return removeFavourite(state, action);
        case actionTypes.SET_FAVOURITES:
            return setFavourites(state, action);

        case actionTypes.SET_FITLER:
            return setFilter(state, action);
        case actionTypes.SET_CATEGORY:
            return setCategory(state, action);
        case actionTypes.SET_TERM:
            return setTerm(state, action);
        case actionTypes.SET_SORT:
            return setSort(state, action);

        case actionTypes.SET_LOCATION:
            return setLocation(state, action);

        default:
            return state;
    }
};

export default reducer;
