import React, { useEffect, useState, useCallback, Fragment } from "react";
import css from "./styles/Filter.module.scss";
import { Link } from "react-router-dom";

import Litepicker from "../../lib/DatePickerModal/DatePickerModal";

import TimePicker from "../TimePicker/TimePicker";
import MorePicker from "../TimePicker/MorePicker";
import ChildPicker from "../TimePicker/ChildPicker";

import LookUpInput from "./_LookUpInput";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import util from "../util/util";
import * as routes from "../util/routes";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import SearchIcon from "@material-ui/icons/Search";

let picker;

const MainPanelFilter = props => {
    let fields = [];
    let refIn = React.createRef();
    let refOut = React.createRef();
    const [chosenObject, chooseObject] = useState({});
    const [more, toggleMore] = useState(false);
    const [addRef, setAddRef] = useState(false);
    const filterConfig = useCallback(param => {
        let config = {};

        // If no category selected, or accommodation is selected -
        // double date picker,
        // else - single date

        config.singleMode = props.category ? !(props.category.id == 7) : false;
        config.datePickerClassName = config.singleMode
            ? util.join(css["input-text-inline"], css["input-text"])
            : css["input-text"];
        // config.datePickerPlaceholder = getDateText(config.singleMode);
        config.dateOutField = config.singleMode
            ? util.join(css["input-text-inline"], css["input-text"])
            : `${css["input-textOut"]} ${css["input-text"]}`;
        if (param && config.hasOwnProperty(param)) {
            return config[param];
        }
        return config;
    });

    useEffect(() => {
        const config = filterConfig();
        const input = refIn.current;
        const input_dateOut = refOut.current;
        if (picker) {
            picker.destroy();
            document.body.classList.remove("litepicker-open");
        }

        picker = new Litepicker({
            element: input,
            elementEnd: config.singleMode ? null : input_dateOut,
            singleMode: config.singleMode,
            numberOfMonths: config.singleMode ? 1 : 2,
            format: "DD.MM.YYYY",
            numberOfColumns: config.singleMode ? 1 : 2,
            minDate: util.moment().add(-1, "day"),
            lang: "ru-RU",
            tooltipText: {
                one: "день",
                few: "дня",
                many: "дней"
            },

            startDate: props.filter.dateIn.format("DD.MM.YYYY"),
            endDate: config.singleMode
                ? null
                : props.filter.dateOut.format("DD.MM.YYYY"),

            onSelect: function(start, end) {
                const _end = end || util.moment(start).add(1, "day");
                props.setFilter({
                    dateIn: util.moment(start),
                    dateOut: util.moment(_end)
                });
            }
        });
    }, [refIn, refOut, filterConfig, props, props.category]);
    const dateIn = props.filter.dateIn.format("DD.MM.YYYY");
    const dateOut =
        props.filter.dateOut.format("DD.MM.YYYY") ||
        props.filter.dateIn.format("DD.MM.YYYY");
    // const categoryUrl = routes.CATEGORY_PREFIX + props.category.code;

    const searchByKeyTerm = () => {
        let searchLink = "/";
        if (props.searchTerm.length > 0) {
            const categoryParam = props.category
                ? `${props.category.code}/`
                : "";
            searchLink =
                routes.SEARCH_PREFIX +
                categoryParam +
                decodeURIComponent(props.searchTerm);
        }
        if (!util.emptyObject(chosenObject)) {
            if (chosenObject.categoryCode && chosenObject.partnerCode) {
                searchLink =
                    routes.CATALOG_PREFIX +
                    chosenObject.categoryCode +
                    "/" +
                    chosenObject.partnerCode;
            }
        }

        return searchLink;
    };

    return (
        <div className={css["container"]}>
            <div className={css["group"]}>
                <div className={css["input-group"]}>
                    <div className={css["input-group-inline"]}>
                        <label
                            className={`${css["input-label"]} ut-theme-color`}
                        >
                            Куда
                        </label>

                        <LookUpInput
                            category={props.category}
                            className={util.join(
                                css["input-text-inlin"],
                                css["input-text"],
                                css["input-left"]
                            )}
                            valueHandler={chooseObject}
                            searchByKey={searchByKeyTerm}
                        />
                    </div>
                </div>
            </div>

            <div className={css["group"]}>
                <div className={css["input-group"]}>
                    <div className={util.join(css["input-group-inline"])}>
                        <label
                            className={`${css["input-label"]} ut-theme-color`}
                        >
                            Когда
                        </label>
                        <input
                            type="text"
                            className={filterConfig("datePickerClassName")}
                            placeholder={filterConfig("datePickerPlaceholder")}
                            readOnly={true}
                            value={dateIn}
                            ref={refIn}
                        />

                        {filterConfig("singleMode") ? (
                            <TimePicker
                                time={props.filter.time}
                                pickHandler={(h, m) =>
                                    props.setFilter({ time: h + ":" + m })
                                }
                                notTime={false}
                            />
                        ) : (
                            <Fragment>
                                <div className={css["input-textOut"]}>-</div>
                                <input
                                    readOnly
                                    value={
                                        filterConfig("singleMode")
                                            ? props.filter.time
                                            : dateOut
                                    }
                                    name="hotel_date_out"
                                    className={filterConfig(
                                        "datePickerClassName"
                                    )}
                                    ref={refOut}
                                />
                            </Fragment>
                        )}

                        <label
                            onClick={() => toggleMore(!more)}
                            className={css["input-btn"]}
                        >
                            <MoreHorizIcon size={24} />
                        </label>
                    </div>

                    <div
                        className={util.join(
                            css["more"],
                            more && css["more--on"]
                        )}
                    >
                        <label
                            className={`${css["input-label"]} ut-theme-color`}
                        >
                            С кем
                        </label>
                        <MorePicker
                            adults={props.filter.adults}
                            more={true}
                            main={true}
                            children={props.filter.children}
                            pickHandler={(ad, ch) => {
                                props.setFilter({
                                    adults: parseInt(ad)
                                });
                                props.setFilter({
                                    children: parseInt(ch)
                                });
                            }}
                        />
                        {fields}
                    </div>
                </div>
                <Link
                    className={util.join(
                        css["btn"],
                        props.searchTerm.length > 0 && "ut-theme-color",
                        props.searchTerm.length > 0 && css["btn--on"]
                    )}
                    to={searchByKeyTerm}
                >
                    {" "}
                    <span>найти</span>
                    <SearchIcon size={16} />{" "}
                </Link>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    category: state.user.customerData.category,
    filter: state.user.customerData.filter,
    searchTerm: state.user.customerData.searchTerm
});

const mapDispatchToProps = dispatch => ({
    setFilter: filter => dispatch(actions.setFilter(filter)),
    setTerm: filter => dispatch(actions.setTerm(filter))
});
export default connect(mapStateToProps, mapDispatchToProps)(MainPanelFilter);
