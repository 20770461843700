/**
 *
 * Refactored version
 *
 * Fall-back cart product layout
 * (while Categories list is being fetched)
 *
 */

import React, { useState } from "react";
import css from "./Cart.module.scss";
import ClearIcon from "@material-ui/icons/Clear";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import ShareIcon from "../Share/ShareWidget";
import CartItemProduct from "./_CartItemProduct_Other";
import UserModel from "../../model/User";
import QuantityInput from "./_QuantityInput";
import util from "../util/util";

const CartItem = (props) => {
	const itemsSummary = props.items.map((item, index) => (
		<li key={index}>
			{item.name} x {item.quantity}
		</li>
	));

	const removeHandler = () => {
		const itemsToRemove = props.items.map((item) => item.id);
		props.removeFromCart(itemsToRemove);
	};

	return (
		<div className={css["item"]}>
			<div className={css["item-main"]}>
				<ul className={util.join("ut-theme-color", css["item-icons"])}>
					<li
						className={`${css["item-icon"]} ${css["item-red"]}`}
						onClick={removeHandler}
					>
						<ClearIcon />
					</li>
					<li>
						<ShareIcon
							items={props.items}
							categoryId={props.categoryId}
							productId={props.productId}
						/>
					</li>
				</ul>

				<img src={props.image} className={css["item-image"]} />
				<div className={css["item-data"]}>
					<h3 className={css["item-title"]}>{props.name}</h3>
					<ul className={css["item-summary"]}>{itemsSummary}</ul>
					<div className={css["item-price"]}>
						Цена {util.currencyFormat(props.total)}
						<span
							className={util.join(
								css["item-toggle"],
								"ut-theme-color"
							)}
						></span>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	removeFromCart: (items) => dispatch(actions.removeFromCart(items)),
});

export default connect(null, mapDispatchToProps)(CartItem);
