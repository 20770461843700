import React, { useState, useEffect } from "react";
import css from "./SearchResults.module.scss";
import util from "../util/util";
import searchUtil from "./searchUtil";
import NotAcomodationSearchItem from "./NotAcomodationSearchItem";
import AcomodationSearchItem from "./AcomodationSearchItem";

const SearchResultItem = props => {
    const [pickerDisplay, setPickerDisplay] = useState(true);
    const [partnerOrNot, setPartnerOrNot] = useState(false);
    useEffect(() => {
        if (props.data.category_id == 7) {
            if (props.count) {
                setPartnerOrNot(true);
            } else {
                if (
                    props.data.params.price == null ||
                    (!props.data.params.productId &&
                        !props.data.params.productid)
                ) {
                    setPartnerOrNot(true);
                } else {
                    setPartnerOrNot(false);
                }
            }
        } else {
            if (
                !props.data.params.productText ||
                (!props.data.params.productText && !props.data.params.price)
            ) {
                setPartnerOrNot(true);
            } else {
                setPartnerOrNot(false);
            }
            if (props.search) {
                if (
                    !props.data.params.productText &&
                    !props.data.params.price &&
                    props.search
                ) {
                    setPartnerOrNot(true);
                } else {
                    setPartnerOrNot(false);
                }
                if (!props.data.params.productId) {
                    setPartnerOrNot(true);
                }
            }
        }
    });

    const change = e => {
        props.getHover(e);
    };

    const price = props.data.params.price
        ? util.currencyFormat(props.data.params.price)
        : "";

    const priceOther = props.data.params.productName
        ? props.data.params.price == 0
            ? " "
            : util.currencyFormat(props.data.params.price)
        : props.data.params.price == 0
        ? " "
        : util.currencyFormat(props.data.params.price);

    const dataLink = searchUtil.prepareLink(props.data, props.category);

    const itemData = {
        id: props.data.id,
        name: props.data.name,
        image: dataLink.image,
        categoryCode: props.category.code,
        partnerCode: props.data.code,
        text: util.getText(props.data.params.services_desc, 100)
    };

    const partnerLink = `/catalog/${props.category.code}/${props.data.code}`;

    const changeDisplay = e => {
        setPickerDisplay(!pickerDisplay);
    };

    const icons = [];
    if (props.data.category_id == 7) {
        icons.push(
            ...props.data.params.services.map((item, index) => (
                <li className={css["serviceParnter"]} key={index}>
                    <i className={"ut-ico-" + item.class}></i>
                </li>
            ))
        );
    }
    const prepareText = () => {
        let descript =
            props.data.category_id == 7
                ? props.data.params.services_desc
                : props.data.params.productText
                ? props.data.params.productText
                : props.data.params.services_desc;
        let cropped = util.getText(descript, 100);

        return cropped;
    };

    const check =
        props.data.category_id == 7 ? (
            <AcomodationSearchItem
                change={change}
                data={props.data}
                category={props.category}
                itemData={itemData}
                pickerDisplay={pickerDisplay}
                changeDisplay={changeDisplay}
                partnerLink={partnerLink}
                partnerOrNot={partnerOrNot}
                priceOther={priceOther}
                addToCart={props.addToCart}
                price={price}
                icons={icons}
                prepareText={prepareText}
            />
        ) : (
            <NotAcomodationSearchItem
                change={change}
                data={props.data}
                category={props.category}
                itemData={itemData}
                pickerDisplay={pickerDisplay}
                changeDisplay={changeDisplay}
                partnerLink={partnerLink}
                partnerOrNot={partnerOrNot}
                search={props.search}
                priceOther={priceOther}
                addToCart={props.addToCart}
            />
        );
    return check;
};

export default SearchResultItem;
