import React from "react";
import css from "./Hero.module.scss";
import Offer from "./_RightPanelOffer";
import util from "../util/util";
import { connect } from "react-redux";

const RightPanel = props => {
  const offers = [];

  for (let i = 0; i < props.offers.length; i++) {
    if (props.offers[i].price == 0) {
      break;
    }
    const itemData = {
      productId: props.offers[i].id,
      categoryId: props.offers[i].category_id,
      dateIn: props.filter.dateIn.format("YYYY-MM-DD"),
      dateOut: props.filter.dateOut.format("YYYY-MM-DD"),
      adults: props.filter.adults,
      children: props.filter.children,
      time: props.filter.time,
      Fname: "",
      Lname: "",
      email: ""
    };
    offers.push(
      <Offer
        parentCode={props.offers[i].parentCode}
        key={i}
        productId={props.offers[i].code}
        title={props.offers[i].name}
        labels={props.offers[i].labels}
        oldprice={props.offers[i].oldPrice}
        price={props.offers[i].price}
        image={props.offers[i].photos[0].small}
        cartHandler={() => props.cartHandler(itemData)}
      />
    );
  }
  return (
    <div className={css["panel-right"]}>
      <div
        className={css["panel-bg"]}
        style={{ backgroundImage: `url(${props.backgroundImage})` }}
      />
      <ul className={css["offers"]}>{offers.length > 0 ? offers : ` `} </ul>
    </div>
  );
};
const mapStateToProps = state => ({
  filter: state.user.customerData.filter
});
export default connect(mapStateToProps)(RightPanel);
