import React, { useState, useEffect, Fragment } from "react";
import Label from "../Label/Label";
import css from "./ProductData.module.scss";
import filterCss from "./_Filter.module.scss";
import GoogleMapReact from "google-map-react";
import SearchResultsMapItem from "../SearchResults/_SearchResultsMapItem";
import ImageGallery from "react-image-gallery";
import "../../assets/scss/image-gallery.scss";
import util from "../util/util";
import ProductsFilter from "../ProductsFilter/ProductsFilter";
import ProductItem from "./_ProductItem";
import Reviews from "../Reviews/Reviews";
import { getSource, initialState } from "../../model/Reviews";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import LoadingMessage from "../Interface/LoadingMessage";
import UserForm from "../UserForm/UserForm";
import RandomReview from "./_RandomReview";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import StarIcon from "@material-ui/icons/Star";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import HomeIcon from "@material-ui/icons/Home";

let reviewsRef;

//Helper sort function
const byLengthASC = (a, b) =>
	a.title.length == b.title.length
		? 0
		: a.title.length < b.title.length
		? -1
		: 1;

const ProductData = (props) => {
	// Reviews data

	// Reset data source before usage
	useEffect(() => {
		reviewsRef = React.createRef();
	}, []);

	// Provide auth form to write a review
	const [userFormVisible, toggleUserForm] = useState(false);
	const [postedReview, setPostedReview] = useState(false);
	const userFormModal = userFormVisible ? (
		<UserForm hideHandler={() => toggleUserForm(false)} />
	) : null;

	const [reviewData, setReviewData] = useState(initialState);
	const [reviewComposeMode, toggleReviewCompose] = useState(false);
	const partnerId = props.data.id;
	const categoryId = props.data.category_id;
	const reviewSource =
		partnerId && categoryId
			? getSource(
					parseInt(partnerId),
					parseInt(categoryId),
					1,
					setReviewData
			  )
			: null;

	// Random Review item block

	const [randomReview, storeRandomReview] = useState(false);

	if (
		reviewData &&
		reviewData.items &&
		reviewData.items.length > 0 &&
		!randomReview
	) {
		const randomElement = util.randomArrayElement(reviewData.items);
		randomElement.itemsLength = reviewData.items.length;
		storeRandomReview(randomElement);
	}

	// scroll to Reviews Section
	const scrollToReviews = () => {
		if (!reviewsRef.current) return false;
		window.scrollTo({ top: reviewsRef.current.offsetTop });
	};

	// post review button click hanlder
	const postBtnClickHandler = () => {
		if (!props.isLogged) return toggleUserForm(true);
		if (reviewData && !reviewData.fields && !reviewData.loading.fields) {
			reviewSource.getReviewFields();
		}
		scrollToReviews();
		if (!postedReview) {
			toggleReviewCompose(true);
		}
	};

	// what happens after review was submitted
	const composeFormSubmitHandler = (data) => {
		const reviewData = { ...data };
		reviewData.partnersId = props.data ? props.data.id : null;
		reviewData.partnersName = props.data ? props.data.name : null;

		reviewSource.addReview(reviewData).then((result) => {
			scrollToReviews();
			alert(result.msg);
			if (!result.error) {
				toggleReviewCompose(false);
				setPostedReview(true);
			}
		});
	};

	const randomReviewBlock = randomReview ? (
		<RandomReview
			{...randomReview}
			scrollBtnClick={() => {
				scrollToReviews();
				toggleReviewCompose(false);
			}}
			postBtnClick={postBtnClickHandler}
			postedReview={postedReview}
		/>
	) : null;

	const reviewsSection = (
		<div ref={reviewsRef}>
			<Reviews
				{...reviewData}
				postedReview={postedReview}
				getNext={reviewSource.getNext}
				postBtnClick={postBtnClickHandler}
				composeMode={reviewComposeMode}
				toggleComposeMode={toggleReviewCompose}
				composeFormSubmitHandler={composeFormSubmitHandler}
			/>
		</div>
	);

	//

	const labels = [];

	const [showAllServices, toggleAllServices] = useState(false);

	const services = [];

	const [mapVisible, mapToggle] = useState(false);

	if (props.params.stars) {
		labels.push(
			<Label key={"stars"}>
				{props.params.stars} <StarIcon />
			</Label>
		);
	}

	if (reviewData && reviewData.totals.overallRating) {
		labels.push(
			<Label key={"rating"} class="blue">
				{reviewData.totals.overallRating}
			</Label>
		);
	}

	if (props.params && props.params.services) {
		const serviceList = showAllServices
			? props.params.services
			: props.params.services.slice(0, 5);
		services.push(
			serviceList.sort(byLengthASC).map((item, index) => (
				<li key={index}>
					<i className={"ut-ico-" + item.class}></i>
					{item.title}
				</li>
			))
		);
		if (!showAllServices) {
			services.push(
				<li
					key="--more"
					className="ut-theme-color"
					onClick={() => toggleAllServices(true)}
				>
					<MoreHorizIcon /> Еще
				</li>
			);
		}
	}

	let products = [];

	const partnerImage = util.getPropertySafe(
		props,
		"data.params.photos.0.small",
		util.dummyImage().small
	);

	let position = util.getPosition(props.data.params.position);
	const categoryCode = props.category;

	let productSection;
	if (!props.products) {
		productSection = (
			<LoadingMessage>
				<p>Ищем доступные предложения...</p>
			</LoadingMessage>
		);
	} else if (Array.isArray(props.products)) {
		for (let i = 0; i < props.products.length; i++) {
			if (parseInt(props.products[i].price) !== 0) {
				const itemData = {
					productId: props.products[i].id,
					categoryId: props.products[i].category_id,
					dateIn: props.filter.dateIn.format("YYYY-MM-DD"),
					dateOut: props.filter.dateOut.format("YYYY-MM-DD"),
					adults: props.filter.adults,
					children: props.filter.children,
					time: props.filter.time,
					Fname: "",
					Lname: "",
					email: "",
				};
				const imageOrPartner = util.getPropertySafe(
					props.products[i],
					"params.photos.0.small",
					partnerImage
				);

				products.push(
					<ProductItem
						key={i}
						data={props.products[i]}
						addHandler={() => props.addToCart(itemData)}
						category={props.category}
						partnerCode={props.data.code}
						partnerName={props.data.name}
						imageOrPartner={imageOrPartner}
						nights={props.nights}
					/>
				);
			}
		}
		productSection = (
			<Fragment>
				<div className={css["filter"]}>
					<h4 className="ut-section__title">Выберите предложение</h4>
					<ProductsFilter
						css={filterCss}
						category={props.data}
						getRefreshProducts={props.getRefreshProducts}
						config={props.filterConfig}
					/>
				</div>
				{products.length > 0 ? (
					<div className={css["products"]}>{products}</div>
				) : (
					<div className={css["text"]}>
						<center>
							<p className={css["text-content"]}>
								К сожалению, поиск не дал результатов.
								Попробуйте изменить параметры поиска
							</p>
						</center>
					</div>
				)}
			</Fragment>
		);
	} else if (props.products == -1) {
		productSection = null;
	}

	const marker = position ? (
		<SearchResultsMapItem
			data={{ ...props.data, category: { ...categoryCode } }}
			{...position}
			categoryCode
		>
			<HomeIcon />
		</SearchResultsMapItem>
	) : null;

	const slides = props.photos.map((item) => ({
		original: item.mid,
		thumbnail: item.small,
	}));

	return (
		<section className="ut-section__container ut-theme-color">
			<div className={css["main"]}>
				<div
					className={util.join(
						`${
							mapVisible
								? css["gallery--visible"]
								: css["gallery"]
						}`
					)}
				>
					{mapVisible && marker ? (
						<GoogleMapReact
							bootstrapURLKeys={{
								key: "AIzaSyBk0yr_pnl0TRs9oQy0j6-qkOqMJR3WLwU",
							}}
							defaultCenter={{
								...position,
							}}
							defaultZoom={10}
							yesIWantToUseGoogleMapApiInternals
						>
							{marker}
						</GoogleMapReact>
					) : (
						<ImageGallery
							items={slides}
							lazyLoad={true}
							showThumbnails={false}
							fillParent={true}
						/>
					)}
				</div>
				<div className={css["data"]}>
					<h1 className={css["title"]}>{props.data.name}</h1>
					<div>{labels}</div>
					<p
						className={util.join(
							css["location"],
							mapVisible && position && css["location--on"]
						)}
						onClick={() => mapToggle(!mapVisible)}
					>
						<LocationOnIcon />
						<a>{props.params.address}</a>
					</p>
					<ul className={css["services"]}>{services}</ul>
					{randomReviewBlock}
				</div>
			</div>
			{productSection}
			{reviewsSection}

			{props.params.services_desc ? (
				<div className={css["text"]}>
					<h3 className={css["text-title"]}>
						{props.params.services_desc_title
							? props.params.services_desc_title
							: "Описание"}
					</h3>
					<div className={css["text-content"]}>
						{util.getHTML(props.params.services_desc)}
					</div>
				</div>
			) : null}
			{props.params.terms ? (
				<div className={css["text"]}>
					<h3 className={css["text-title"]}>
						{props.params.terms_title
							? props.params.terms_title
							: "Условия оказания услуги"}
					</h3>
					<div className={css["text-content"]}>
						{util.getHTML(props.params.terms)}
					</div>
				</div>
			) : null}
			{props.params.note ? (
				<div className={css["text"]}>
					<h3 className={css["text-title"]}>
						{props.params.note_title
							? props.params.note_title
							: "Примечания*"}
					</h3>
					<div className={css["text-content"]}>
						{util.getHTML(props.params.note)}
					</div>
				</div>
			) : (
				""
			)}
			{userFormModal}
		</section>
	);
};

const mapStateToProps = (state) => ({
	isLogged: state.user.isLogged,
	cart: state.user.cart,
	filter: state.user.customerData.filter,
});

const mapDispatchToProps = (dispatch) => ({
	addToCart: (item) => dispatch(actions.addToCart(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductData);
