import React from "react";
import classes from "./styles/LeftPanel.module.scss";
import util from "../util/util";
import css from "./styles/LeftPanel.module.scss";

const LeftPanelCategory = props => (
    <li className={`${classes["category"]} ut-theme-color`}>
        <input
            type="radio"
            id={`hero__option${props.index}`}
            name="hero_category"
            value={props.category.name}
            checked={props.checked}
            readOnly
        />
        <label
            htmlFor={`hero__option${props.index}`}
            onClick={() => props.onClick(props.category)}
        >
            <div
                className={css["category-icon"]}
                style={{
                    backgroundImage:
                        "url(" + util.prependHostname(props.category.icon) + ")"
                }}
            />

            <p>{props.category.name}</p>
        </label>
    </li>
);

export default LeftPanelCategory;
