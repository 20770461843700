import React, { Component, Fragment } from "react";
// import SearchResultsFilter from "./_SearchResultsFilter2";
import SearchResultsFilter from "./_SearchResultsFilter";
import LoadingMessage from "../Interface/LoadingMessage";
import SearchResultsList from "./_SearchResultsList2";
import SearchResultsButton from "./_SearchResultsButton";
import css from "./SearchResults.module.scss";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import util from "../util/util";
import withLoading from "../../hoc/Loading/Loading";
import SearchResultsMapItem from "./_SearchResultsMapItem";
import Categories from "../../model/Categories";
import api from "../API/new/API";
import * as actions from "../../store/actions/index";

const defaultCategoryId = 7;
const gridSize = () => {
	const SMALL = 399;
	const MED = 767;
	const GRID = {
		small: 1,
		med: 2,
		large: 3,
	};
	return window.innerWidth <= SMALL
		? GRID.small
		: window.innerWidth <= MED
		? GRID.med
		: GRID.large;
};

class SearchResults extends Component {
	state = {
		totals: [],
		activeCategoryId: null,
		data: [],
		hoverId: false,
		noResults: false,
		filter: {
			subcat: null,
			sortAsc: true,
		},

		/* 		pressed: false,
		endPartners: false,
		categoryPartners: [],
		
		endOfBest: false,
		first: false, */

		loadSize: gridSize(),
	};

	componentDidMount() {
		this.props.loading(true);
		window.addEventListener("resize", () => {
			const loadSize = gridSize();
			this.setState((state) => ({
				...state,
				loadSize,
			}));
		});

		this.getTotals();
	}

	componentDidUpdate(prevProps, prevState) {
		//  Update component when catalog data is available

		if (
			this.props.catalog.length !== prevProps.catalog.length ||
			this.props.term !== prevProps.term
		) {
			this.getTotals();
		}
	}

	/**
	 *
	 * Helper function to lighten map balloon
	 * on search results list item hover
	 *
	 *
	 */
	getHoveredProduct = (e) => {
		this.setState({ hoverId: e });
	};

	/**
	 *
	 * Changes active category
	 *
	 */

	toggleCategory = (categoryId) => {
		this.props.setFilter({
			...this.props.filter,
			subCategory: null,
		});
		this.setState((state) => ({
			...state,
			activeCategoryId: categoryId,
		}));
	};

	/**
	 *
	 * Get total number of results
	 * in each category and correspondoing SKU block
	 *
	 */
	getTotals = () => {
		this.props.loading(true);
		const updatePromises = [];
		const totals = [];
		if (!this.props.catalog || this.props.catalog.length < 1) return;
		this.props.catalog.forEach((category) => {
			const reqParams = this.getReqParams(category, true);
			const updatePromise = api.search.SearchPartners(reqParams.category);
			updatePromise.then((response) => {
				const items = util.getPropertySafe(
					response,
					"data.totalItems",
					0
				);
				totals[category.id] = totals[category.id]
					? totals[category.id] + items
					: items;
			});
			updatePromises.push(updatePromise);
			if (reqParams.sku) {
				const skuUpdatePromise = api.search.SearchPartners(
					reqParams.sku
				);
				skuUpdatePromise.then((response) => {
					const items = util.getPropertySafe(
						response,
						"data.totalItems",
						0
					);
					totals[category.id] = totals[category.id]
						? totals[category.id] + items
						: items;
				});
				updatePromises.push(skuUpdatePromise);
			}
		});
		Promise.all(updatePromises).then(() => {
			const activeCategoryId = this.defineActiveCategory(totals);
			const noResults = !totals.some((item) => item);
			this.setState((state) => ({
				...state,
				totals,
				noResults,
				activeCategoryId,
			}));
			this.props.loading(false);
		});
	};

	/**
	 * Defines active category from possible values
	 * @param {*} category
	 * @param {*} totals
	 */

	defineActiveCategory = (totals) => {
		let activeCategoryId;
		if (this.props.category) {
			activeCategoryId = this.props.category.id;
		}
		if (totals[activeCategoryId] > 0) return activeCategoryId;
		if (totals[defaultCategoryId] > 0) return defaultCategoryId;
		activeCategoryId = null;
		totals.map((item, index) => {
			if (item && !activeCategoryId) activeCategoryId = index;
		});
		return activeCategoryId;
	};

	/**
	 * Prepare parameters for category request
	 * @param {*} category
	 * @param {*} totals
	 */

	getReqParams(category, totals = false) {
		const skuParams = category.skuBlockId
			? {
					term: this.props.term,
					categoryId: category.skuBlockId,
					getTotalItems: totals,
			  }
			: null;

		return {
			category: {
				term: this.props.term,
				categoryId: category.id,
				getTotalItems: totals,
			},
			sku: skuParams,
		};
	}

	/**
	 *
	 * Render category buttons list
	 *
	 */
	renderButtons = () => {
		if (!this.props.catalog) return null;
		const buttons = this.state.totals.map((item, categoryId) => {
			if (!item) return null;
			const category = Categories.byId(categoryId, this.props.catalog);
			return (
				<SearchResultsButton
					key={category.id}
					selected={category.id == this.state.activeCategoryId}
					onClick={() => this.toggleCategory(category.id)}
				>
					{category.name} ({item})
				</SearchResultsButton>
			);
		});

		return buttons.map((button) => button);
	};

	/**
	 *
	 * Fetches items for current category
	 *
	 */
	getItems = () => {
		const { activeCategoryId } = { ...this.state };
		if (!activeCategoryId) return [];
		if (this.state.data[activeCategoryId])
			return this.state.data[activeCategoryId];
		const categoryData = Categories.byId(
			activeCategoryId,
			this.props.catalog
		);
		const reqParams = this.getReqParams(categoryData);
		const allItems = [];
		const updatePromises = [];
		const updatePromise = api.search.SearchPartners(reqParams.category);
		updatePromise.then((response) => {
			const items = util.getPropertySafe(response, "data.items", null);
			if (items) {
				allItems.push(...items);
			}
		});
		updatePromises.push(updatePromise);
		if (reqParams.sku) {
			const skuUpdatePromise = api.search.SearchPartners(reqParams.sku);
			skuUpdatePromise.then((response) => {
				const items = util.getPropertySafe(
					response,
					"data.items",
					null
				);
				if (items) {
					allItems.push(...items);
				}
			});
			updatePromises.push(skuUpdatePromise);
		}

		Promise.all(updatePromises).then(() => {
			const data = [...this.state.data];
			data[activeCategoryId] = [...allItems];
			this.setState((state) => ({
				...state,
				data,
			}));
		});
		return [];
	};

/**
 * 
 * Fitlers out Subcategories that are present in products list
 * 
 * @param {array} subcategories 
 * @param {array} products 
 */

	filterSubcategories(subCategories, products) {
		const validSubcategories = products.reduce((acc,cur)=>{
			return acc.indexOf(cur.subcategory_id) === -1 ?
				[...acc,cur.subcategory_id] :
				acc;
		},[]);
		console.log(subCategories,products,validSubcategories)
		return subCategories.filter((subCat)=>(validSubcategories.indexOf(subCat.id) > -1))

	}


	/**
	 *
	 * return sorted and filtered items
	 *
	 */

	sortItems = (items) => {
		if (items.length < 2) return items;
		const subCatId = util.getPropertySafe(
			this.props,
			"filter.subCategory.id",
			null
		);
		const itemsOfSubCat = subCatId
			? items.filter((item) => item.subcategory_id == subCatId)
			: items;
		
		return itemsOfSubCat.sort((a, b) => {
			let aPrice = parseInt(util.getPropertySafe(a, "params.price", 0));
			if (isNaN(aPrice)) aPrice = 0;
			let bPrice = parseInt(util.getPropertySafe(b, "params.price", 0));
			if (isNaN(bPrice)) bPrice = 0;

			return this.props.sort.toUpperCase() == "ASC"
				? aPrice - bPrice
				: bPrice - aPrice;
		});
	};

	/**
	 *
	 * Renders map component
	 *
	 */

	renderMap = (items) => {
		const _currentCategoryData = Categories.byId(
			this.state.activeCategoryId,
			this.props.catalog
		);

		

		const _mapElements = items
			.filter((item) => util.getPosition(item.params.position))
			.map((item, index) => {
				const price = parseInt(
					util.getPropertySafe(item, "params.price")
				);
				const photoUrl = util.getPropertySafe(
					item,
					"params.photos.0.small",
					util.dummyImage().small
				);

				const content =
					price > 0 ? (
						<span>{util.currencyFormat(price)}</span>
					) : (
						<span>
							<img
								src={photoUrl}
								width={24}
								height={24}
								style={{ borderRadius: "100%" }}
							/>
						</span>
					);

				return (
					<SearchResultsMapItem
						key={index}
						data={{
							...item,
							category: { ..._currentCategoryData },
						}}
						lat={item.params.position.split(",")[0]}
						lng={item.params.position.split(",")[1]}
						hoverId={this.state.hoverId}
						getHover={this.getHoveredProduct}
					>
						{content}
					</SearchResultsMapItem>
				);
			});

		const map = (
			<div className={css["map"]}>
				<div className={css["map-container"]}>
					<GoogleMapReact
						onClick={this.getHoveredProduct}
						bootstrapURLKeys={{
							key: "AIzaSyBk0yr_pnl0TRs9oQy0j6-qkOqMJR3WLwU",
						}}
						defaultCenter={{
							lat: 43.6829697,
							lng: 40.2491973,
						}}
						defaultZoom={12}
						yesIWantToUseGoogleMapApiInternals
					>
						{_mapElements}
					</GoogleMapReact>
				</div>
			</div>
		);
		return map;
	};

	/**
	 * 
	 * Component render function
	 * 
	 */

	render = () => {
		const buttons = this.renderButtons();
		const categoryData = Categories.byId(
			this.state.activeCategoryId,
			this.props.catalog
		);
		
		const allItems = this.getItems();
		const items = this.sortItems(allItems);
		
		const validSubcategories = categoryData ?
		this.filterSubcategories(categoryData.subcat,allItems):[];
		
		
		const map = this.renderMap(items);
		const filter = categoryData ? (
			<SearchResultsFilter
				title={categoryData.name}
				category={categoryData}
				subCategories={validSubcategories}
				refresh={() => {}}
				hideButton={true}
			/>
		) : null;
		if (this.state.noResults) {
			return (
				<div className={css["main"]}>
					<p>Ничего не найдено</p>
				</div>
			);
		}

		return (
			<Fragment>
				<div className={css["wrapper-btn-mobile"]}>{buttons}</div>
				<div></div>
				<div className={css["wrapper-btn"]}>{buttons}</div>
				{filter}
				<div className={css["main"]}>
					{items.length > 0 ? (
						<SearchResultsList
							category={categoryData}
							products={items}
							getHover={this.getHoveredProduct}
							hoverId={this.state.hoverId}
							loadSize={this.state.loadSize}
							disableBtn={true}
						/>
					) : (
						this.state.totals.length > 0 && (
							<LoadingMessage>
								<p>Загружаем тороговые предложения...</p>
							</LoadingMessage>
						)
					)}

					{map}
				</div>
			</Fragment>
		);
	};
}

const mapStateToProps = (state) => ({
	catalog: state.catalog.categories,
	category: state.user.customerData.category,
	term: state.user.customerData.searchTerm,
	filter: state.user.customerData.filter,
	sort: state.user.customerData.sort,
});
const mapDispatchToProps = (dispatch) => ({
	setCategory: (category) => {
		dispatch(actions.setCategory(category));
	},
	setFilter: (filter) => {
		dispatch(actions.setFilter(filter));
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withLoading(SearchResults));

const sortByPriceAsc = (a, b) => {};
