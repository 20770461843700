import React, { useState, Fragment } from "react";
import css from "./ShareWidget.module.scss";
import ShareIcon from "@material-ui/icons/Share";
import util from "../util/util";
import api from "../API/new/API";

import {
	FacebookShareButton,
	InstapaperShareButton,
	TwitterShareButton,
	VKShareButton,
	WhatsappShareButton,
	FacebookIcon,
	WhatsappIcon,
	TwitterIcon,
	VKIcon,
} from "react-share";

const ShareWidget = (props) => {
	const [pickerDisplay, setPickerDisplay] = useState(true);
	const [shareState, setShareState] = useState(true);

	const changeDisplay = (e) => {
		if (pickerDisplay) {
			getLink().then((response) => {
				if (response.data) {
					setShareState(response.data);
				}
			});
		}
		setPickerDisplay(!pickerDisplay);
	};

	const getLink = () => {
		const items = props.items
			.map((item) => {
				const assocItem = util.arrayToAssoc(
					item.params,
					"code",
					"value"
				);
				if (Object.keys(assocItem).length > 0) {
					const shareObject = {
						...assocItem,
						productId: item.productId,
						categoryId: props.categoryId,
					};

					return shareObject;
				}
				return false;
			})
			.filter((item) => item);

		const params = {
			cart: JSON.stringify(items),
		};

		return api.commerce.GetCartShareLink(params);
	};

	return (
		<Fragment>
			<span onClick={changeDisplay} className={css["item-icon"]}>
				<ShareIcon />
			</span>
			<ul
				className={
					pickerDisplay ? css["item-socials"] : css["displayBlock"]
				}
			>
				<li className={" ut-theme-color"}>
					<VKShareButton
						url={shareState}
						title={"Моя корзина на UniqTrip"}
					>
						<VKIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</VKShareButton>
				</li>
				<li className={" ut-theme-color"}>
					<WhatsappShareButton
						url={shareState}
						title={"Моя корзина на UniqTrip"}
						separator={" "}
					>
						<WhatsappIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</WhatsappShareButton>
				</li>

				<li className={" ut-theme-color"}>
					<TwitterShareButton
						url={shareState}
						quote={"Моя корзина на UniqTrip"}
						hashtag={"#UniqTrip"}
					>
						<TwitterIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</TwitterShareButton>
				</li>
				<li className={" ut-theme-color"}>
					<FacebookShareButton
						url={shareState}
						quote={"Моя корзина на UniqTrip"}
						hashtag={"#UniqTrip"}
					>
						<FacebookIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</FacebookShareButton>
				</li>
			</ul>
		</Fragment>
	);
};

export default ShareWidget;
