import React, { useState } from "react";
import css from "./ProductItem.module.scss";
import LikeSwitch from "../LikeSwitch/LikeSwitch";
import style from "../SearchResults/SearchResults.module.scss";
import util from "../util/util";
import { createObject } from "../../model/Favourites";
import ShareIcon from "@material-ui/icons/Share";
import PropTypes from "prop-types";
import {
	FacebookShareButton,
	TwitterShareButton,
	VKShareButton,
	WhatsappShareButton,
	FacebookIcon,
	WhatsappIcon,
	TwitterIcon,
	VKIcon,
} from "react-share";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const ProductItem = (props) => {
	const [pickerDisplay, setPickerDisplay] = useState(true);
	const [hideMoreText, setHideMoreText] = useState(true);

	const gridSize = () => {
		const SMALL = 768;
		const MED = 1000;
		const GRID = {
			small: 1,
			med: 2,
			large: 3,
		};
		return window.innerWidth <= SMALL
			? GRID.small
			: window.innerWidth <= MED
			? GRID.med
			: GRID.large;
	};

	const changeDisplay = (e) => {
		setPickerDisplay(!pickerDisplay);
	};
	const img = util.getPropertySafe(
		props.data,
		"params.photos.0.small",
		util.dummyImage().small
	);

	const itemData = createObject(
		props.data.id,
		props.data.name,
		props.imageOrPartner,
		props.category,
		props.partnerCode,
		props.partnerName
	);
	const _url = `${window.location.href}`;
	const _title = props.data.name;
	const _image = util.getPropertySafe(
		props.data,
		"params.photos.0.small",
		util.dummyImage().small
	);

	const showDescribeText = () => {
		let text = props.data.services_desc
			? props.data.services_desc.slice(0, 350)
			: null;
		let checkSize = gridSize();

		if (checkSize <= 2) {
			text = props.data.services_desc
				? props.data.services_desc.slice(0, 130)
				: null;
		}

		return hideMoreText
			? util.getHTML(text)
			: util.getHTML(props.data.services_desc);
	};
	const getText = () => {
		let checkLength = 350;
		let checkSize = gridSize();
		if (checkSize <= 2) {
			checkLength = 130;
		}
		return (
			props.data.services_desc &&
			props.data.services_desc.length >= checkLength
		);
	};
	return (
		<div className={`${style["item"]} ${css["whiteBack"]}`}>
			<LikeSwitch itemData={itemData} />

			<ul
				className={
					pickerDisplay
						? style["item-socials"]
						: style["displayBlock"]
				}
			>
				<li>
					<button onClick={changeDisplay}>
						<ShareIcon />
					</button>
				</li>
				<li>
					<VKShareButton url={_url} title={_title} image={_image}>
						<VKIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</VKShareButton>
				</li>
				<li>
					<WhatsappShareButton
						url={_url}
						title={_title}
						separator={" "}
						image={_image}
					>
						<WhatsappIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</WhatsappShareButton>
				</li>

				<li>
					<TwitterShareButton
						url={_url}
						quote={_title}
						hashtag={"#UniqTrip"}
					>
						<TwitterIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</TwitterShareButton>
				</li>
				<li>
					<FacebookShareButton
						className={style["shareBtn"]}
						url={_url}
						quote={_title}
						hashtag={"#UniqTrip"}
					>
						<FacebookIcon
							size={20}
							className="ut-theme-color ut-share"
							round={true}
						/>
					</FacebookShareButton>
				</li>
			</ul>

			<img className={css["image"]} src={img} />
			<div className={css["description"]}>
				<h6 className={css["title"]}>{props.data.name}</h6>
				<div
					className={
						hideMoreText
							? css["text"]
							: util.join(css["text"], css["text--open"])
					}
				>
					{showDescribeText()}
				</div>
				{getText() ? (
					<p
						className={css["showText"]}
						onClick={() => {
							setHideMoreText(!hideMoreText);
						}}
					>
						Подробнее
						<span
							className={
								hideMoreText ? css["corner"] : css["cornerOpen"]
							}
						></span>
					</p>
				) : (
					""
				)}
			</div>
			<div className={css["prices"]}>
				<p className={css["price"]}>
					{util.currencyFormat(
						parseInt(props.data.price) > 0 ? props.data.price : 0
					)}
				</p>
				<p className={css["hint"]}>
					{props.data.category_id == 7
						? `стоимость за ${props.nights} ночь (-ей)`
						: null}
				</p>
				<div className={css["buttons"]}>
					<button
						onClick={props.addHandler}
						className={`${style["filter-btn"]} ut-theme-color`}
					>
						<ShoppingCartIcon size={12} />

						<span>Забронировать</span>
					</button>
				</div>
			</div>
		</div>
	);
};

ProductItem.defaultProps = {
	data: { name: "", services_desc: "", price: "", category_id: 0 },
	addHandler: () => {},
	category: "",
	partnerCode: "",
	partnerName: "",
	imageOrPartner: util.dummyImage().small,
};

ProductItem.propTypes = {
	data: PropTypes.object.isRequired,
	addHandler: PropTypes.func.isRequired,
	category: PropTypes.string.isRequired,
	partnerCode: PropTypes.string.isRequired,
	partnerName: PropTypes.string,
	imageOrPartner: PropTypes.string.isRequired,
};

export default ProductItem;
