import React from "react";

import CartItem from "./_CartItem";
import CartItemAcc from "./_CartItem_Acc";
import CartItemOther from "./_CartItem_Other";
import css from "./Cart.module.scss";
import CategoriesModel from "../../model/Categories";
import util from "../util/util";
const Cart = props => {
    const partners = props.partners.map((item, index) => {
        const partnerCategory = CategoriesModel.byId(
            item.categoryId,
            props.categories
        );

        // If we have no categories loaded yet
        if (!partnerCategory) {
            return (
                <CartItem
                    key={index}
                    {...item}
                    onRemove={props.removeHandler}
                />
            );
        }

        switch (partnerCategory.code) {
            case CategoriesModel.types.ACCOMMODATION:
                return <CartItemAcc key={index} {...item} />;
                break;

            default:
                return (
                    <CartItemOther
                        key={index}
                        {...item}
                        onRemove={props.removeHandler}
                    />
                );
        }
    });

    return (
        <div className={css["container"]}>
            {partners.length > 0 ? partners : "Ваша корзина пуста"}
        </div>
    );
};

export default Cart;
