import React, { useState } from "react";
import { connect } from "react-redux";
import css from "./styles/Lookup.module.scss";
import * as actions from "../../store/actions/index";
import { useHistory } from "react-router-dom";

const LookUpInput = props => {
    const [value, setValue] = useState("");
    const history = useHistory();

    const clearHandler = () => {
        setValue("");
        props.setTerm("");
    };

    const handleKeyDown = e => {
        if (e.key === "Enter") {
            const link = props.searchByKey();
            history.push(link);
        }
    };

    const handleInput = e => {
        const value = e.target.value;
        setValue(value);
        props.setTerm(value);
    };

    return (
        <div className={css["group"]}>
            <input
                type="text"
                onChange={handleInput}
                className={props.className}
                placeholder="Введите наименование..."
                value={value}
                onKeyDown={handleKeyDown}
            />

            {value.length > 0 ? (
                <a className={css["clear"]} onClick={clearHandler} />
            ) : (
                ""
            )}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        catalog: state.catalog,
        category: state.user.customerData.category,
        value: state.user.customerData.searchTerm,
        searchTerm: state.user.customerData.searchTerm
    };
};

const mapDispatchToProps = dispatch => ({
    setTerm: text => dispatch(actions.setTerm(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(LookUpInput);
