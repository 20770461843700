import * as actionTypes from "../actionTypes";
import api from "../../../components/API/new/API";

export const getCountriesList = () => {
  return dispatch => {
    api.catalog
      .GetCountriesList()
      .then(response => {
        if (!response.data.error) {
          const data = response.data;
          dispatch({
            type: actionTypes.SET_COUNTRIES,
            data
          });
        } else {
          const message = response.data.msg
            ? response.data.msg
            : "Невозможно получить список стран";
          dispatch({
            type: actionTypes.REQ_FAILED,
            error: message
          });
        }
      })
      .catch(e => {
        const message = "Произошла ошибка сервера";
        dispatch({
          type: actionTypes.REQ_FAILED,
          error: message
        });
      });
  };
};
export const getCategories = () => {
  return dispatch => {
    api.catalog
      .GetSubCategory()
      .then(response => {
        if (!response.data.error) {
          const data = response.data;
          dispatch({
            type: actionTypes.SET_CATEGORIES,
            data
          });
        } else {
          const message = response.data.msg
            ? response.data.msg
            : "Невозможно получить категории каталога";
          dispatch({
            type: actionTypes.REQ_FAILED,
            error: message
          });
        }
      })
      .catch(e => {
        const message = "Произошла ошибка сервера";
        dispatch({
          type: actionTypes.REQ_FAILED,
          error: message
        });
      });
  };
};
export const getBanners = () => {
  return dispatch => {
    api.catalog
      .GetBanners()
      .then(response => {
        if (!response.data.error) {
          const data = response.data;
          dispatch({
            type: actionTypes.SET_BANNERS,
            data
          });
        } else {
          const message = response.data.msg
            ? response.data.msg
            : "Невозможно получить категории каталога";
          dispatch({
            type: actionTypes.REQ_FAILED,
            error: message
          });
        }
      })
      .catch(e => {
        const message = "Произошла ошибка сервера";
        dispatch({
          type: actionTypes.REQ_FAILED,
          error: message
        });
      });
  };
};
