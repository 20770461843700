import React, { useState, useEffect } from "react";
import css from "./Footer.module.scss";
import util from "../util/util";
import { Link } from "react-router-dom";
import BusinessIcon from "@material-ui/icons/Business";
import PhoneCallbackIcon from "@material-ui/icons/PhoneCallback";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AndroidIcon from "@material-ui/icons/Android";
import AppleIcon from "@material-ui/icons/Apple";
import PhoneIcon from "@material-ui/icons/Phone";
import * as routes from "../util/routes";
const Footer = () => {
	return (
		<footer className={`${css["footer"]}`}>
			<div className={util.join(css["container"], "ut-theme-color")}>
				<div
					className={[
						css["form-adress"],
						"ut-section__container",
					].join(" ")}
				>
					<section className={css["form-container"]}>
						<div id="bx24_form_inline"></div>
					</section>
					<section className={css["address-container"]}>
						<h3>Наши контакты</h3>

						<address className={css["address-container-address"]}>
							<ul>
								<li>
									<BusinessIcon />
									<h4>Наш адрес</h4>
									<p>
										г. Сочи, Олимпийский проспект, 21,
										помещение 350
									</p>
								</li>
								<li>
									<ScheduleIcon />

									<h4>Время работы</h4>
									<p>Пн - сб: 09.00 -18.00</p>
								</li>
								<li>
									<a href="tel:+78622777042">
										<PhoneCallbackIcon />
										<h4>Номер телефона</h4>
										<p>+7 862 277 70 42</p>
									</a>
								</li>
								<li>
									<a href="mailto:info@uniqtrip.ru">
										<MailOutlineIcon />
										<h4>Email</h4>
										<p>info@uniqtrip.ru</p>
									</a>
								</li>
							</ul>
						</address>
						<p className={css["address-container-descript"]}>
							Общество с ограниченной ответственностью "УНИКАЛЬНОЕ
							ПУТЕШЕСТВИЕ"
							<br />
							ИНН 2367010913 ОГРН 1192375066586 КПП 236701001
							<br />
							Расчетный счет 407028104
							<br />
							Банк АО «Тинькофф Банк»
							<br />
						</p>
						<div className={css["footer-app"]}>
							<a href="https://play.google.com/store/apps/details?id=com.uniqtrip.uniqtrip">
								<AndroidIcon className={"ut-theme-color"} />
							</a>
							<a href="https://apps.apple.com/us/app/uniqtrip/id1499071482?l=ru&ls=1">
								<AppleIcon className={"ut-theme-color"} />
							</a>
							<span>
								Наше приложение в AppStore и Google Play
							</span>
						</div>
					</section>
				</div>
				<div className={css["logo"]}>
					<p>
						© 2020 All rights reserved{" "}
						<Link
							to={"/"}
							className={[
								css["logo-link"],
								"ut-theme-color",
							].join(" ")}
						>
							<span> Uniq</span>Trip
						</Link>
					</p>
					<a
						href="https://shop.uniqtrip.ru/mobile/security_policy.pdf"
						className={[css["linkBottom"], "ut-theme-color"].join(
							" "
						)}
					>
						Политика конфиденциальности
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
