import React, { Component } from "react";
import { connect } from "react-redux";
import AuthForm from "../components/UserForm/_AuthForm";
import UserData from "../components/UserData/UserData";
import { Panel } from "../components/Interface/Interface";
import * as actions from "../store/actions/index";
import apiDev from "../components/API/API.dev";

class LayoutProfile extends Component {
  state = {
    emergency: true
  };
  componentDidMount() {
    //this.props.view();
    document.title = "Uniqtrip - конструктор приключений: Профиль";
    apiDev("settings/checkEmergencyStatus").then(resp => {
      console.log(resp.data);
      if (typeof resp.data == "object") {
        if (Object.entries(resp.data).length === 0) {
          this.setState({
            ...this.state,
            emergency: false
          });
        } else {
          if (resp.data.stopSales == undefined) {
            this.setState({
              ...this.state,
              emergency: false
            });
          } else {
            this.setState({
              ...this.state,
              emergency: resp.data.stopSales
            });
          }
        }
      }
    });
  }

  render = () => {
    return (
      <section className="ut-section__container">
        {this.props.user && this.props.user.isLogged ? (
          <UserData
            {...this.props.user}
            logout={this.props.logout}
            emergency={this.state.emergency}
          />
        ) : (
          <Panel>
            <AuthForm />
          </Panel>
        )}
      </section>
    );
  };
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(actions.login(email, password)),
  logout: () => dispatch(actions.logout()),
  view: () => dispatch(actions.view())
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutProfile);
