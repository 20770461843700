export const GET_CART = "GET_CART";
// export const GET_ORDERS = "GET_ORDERS";
export const SET_USER = "SET_USER";
export const ADD_FAVOURITE = "ADD_FAVOURITE";
export const REMOVE_FAVOURITE = "REMOVE_FAVOURITE";

export const RESPONSE_ERROR = "RESPONSE_ERROR";

export const AUTH_PENDING = "AUTH_PENDING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_RESET = "AUTH_RESET";
export const AUTH_NOT_PERFORMED = "AUTH_NOT_PERFORMED";

export const UPDATE_PENDING = "UPDATE_PENDING";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAILED = "UPDATE_FAILED";
export const UPDATE_RESET = "UPDATE_RESET";
export const UPDATE_NOT_PERFORMED = "UPDATE_NOT_PERFORMED";

export const UPLOAD_PHOTO_SUCCESS = "UPLOAD_PHOTO_SUCCESS";

export const REG_PENDING = "REG_PENDING";
export const REG_SUCCESS = "REG_SUCCESS";
export const REG_FAILED = "REG_FAILED";
export const REG_RESET = "REG_RESET";
export const REG_NOT_PERFORMED = "REG_NOT_PERFORMED";

export const SET_CATEGORIES = "SET_CATEGORIES";
export const REQ_FAILED = "REQ_FAILED";

export const SET_FITLER = "SET_FILTER";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_TERM = "SET_TERM";
export const SET_SORT = "SET_SORT";

export const SET_LOCATION = "SET_LOCATION";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_BANNERS = "SET_BANNERS";
export const SET_FAVOURITES = "SET_FAVOURITES";
