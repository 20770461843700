import * as actionTypes from "../actions/actionTypes";

const InitialState = {
  categories: [],
  countries: [],
  banners: []
};

const setCategories = (state, action) => {
  const categories = action.data;
  return {
    ...state,
    categories
  };
};
const setCountries = (state, action) => {
  const countries = [...action.data];
  return {
    ...state,
    countries
  };
};
const setBanners = (state, action) => {
  const banners = action.data;
  return {
    ...state,
    banners
  };
};

const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CATEGORIES:
      return setCategories(state, action);
    case actionTypes.SET_COUNTRIES:
      return setCountries(state, action);
    case actionTypes.SET_BANNERS:
      return setBanners(state, action);
    default:
      return state;
  }
};

export default reducer;
