import React, { Fragment, useState, useEffect } from "react";
import CartItemField from "./_CartItemField";
import util from "../util/util";
import css from "./Cart.module.scss";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Button } from "../Interface/Interface";
import PropTypes from "prop-types";

const CartItemProduct = (props) => {
	const assocParams = util.arrayToAssoc(props.params, "code");
	const assocParamsRepeate = util.arrayToAssoc(props.params, "code");

	const [isChange, toggleIsChange] = useState(true);

	const [fields, updateFields] = useState(assocParams);
	const [controlRepeat, setControlRepeat] = useState(assocParamsRepeate);
	const [dirty, setdirty] = useState(true);
	const [focus, setFocus] = useState(false);

	useEffect(() => {
		checkEmpty();
	}, []);

	const fieldChangeHandler = (e) => {
		if (!fields[e.target.name]["edit"]) return fields;
		const updatedFields = { ...fields };
		updatedFields[e.target.name]["value"] = e.target.value;
		checkIdentical();
		return updateFields(updatedFields);
	};
	const checkIdentical = () => {
		setdirty(true);
		for (let propertyName in fields) {
			if (
				fields[propertyName]["value"] !==
				controlRepeat[propertyName]["value"]
			) {
				setdirty(false);
				break;
			}
		}
	};
	const checkEmpty = () => {
		for (const key in fields) {
			if (fields.hasOwnProperty(key)) {
				const edit = fields[key]["edit"];
				const value = fields[key]["value"];
				if (edit && value && value.length > 0 && value != 0) {
					toggleIsChange(false);
				}
			}
		}
	};
	const changeButton = () => {
		toggleIsChange(true);
		setFocus(true);
	};
	const params = (
		<Fragment>
			<CartItemField
				{...fields["Fname"]}
				changeHandler={fieldChangeHandler}
				isChange={isChange}
				focus={focus}
			/>
			<CartItemField
				{...fields["Lname"]}
				changeHandler={fieldChangeHandler}
				isChange={isChange}
			/>
			<CartItemField
				{...fields["email"]}
				changeHandler={fieldChangeHandler}
				isChange={isChange}
			/>
			<CartItemField
				{...fields["phone"]}
				changeHandler={fieldChangeHandler}
				isChange={isChange}
			></CartItemField>

			<CartItemField
				{...fields["dateIn"]}
				changeHandler={fieldChangeHandler}
				isChange={isChange}
			/>
			<CartItemField
				{...fields["dateOut"]}
				changeHandler={fieldChangeHandler}
				isChange={isChange}
			/>
			<CartItemField
				{...fields["adults"]}
				changeHandler={fieldChangeHandler}
				isChange={isChange}
			/>
			<CartItemField
				{...fields["children"]}
				changeHandler={fieldChangeHandler}
				isChange={isChange}
			/>
			<CartItemField
				{...fields["time"]}
				changeHandler={fieldChangeHandler}
				isChange={isChange}
			/>
		</Fragment>
	);

	return (
		<div className={css["product"]}>
			<h5 className={css["product-title"]}>{props.name}</h5>
			<a
				className={css["product-duplicate"]}
				title="Еще один номер"
				onClick={props.duplicateHandler}
			>
				<AddBoxIcon />
			</a>
			<a className={css["product-remove"]} onClick={props.removeHandler}>
				<DeleteForeverIcon />
			</a>
			<p className={css["product-text"]}>
				{util.ellipse(props.text, 160)}
			</p>
			<ul>{params}</ul>
			<div className={util.join(css["product"], "ut-theme-color")}>
				{isChange ? (
					<Button
						onClick={() => props.updateHandler(fields)}
						className={`${
							isChange
								? isChange && dirty
									? css["product-button"]
									: util.join(
											"ut-theme-color",
											css["product-button--dirty"]
									  )
								: dirty
								? css["product-button"]
								: util.join(
										"ut-theme-color",
										css["product-button--dirty"]
								  )
						}`}
						children={"Сохранить"}
						loading={props.loading}
					></Button>
				) : (
					<Button
						onClick={() => changeButton()}
						className={`${
							isChange
								? isChange && dirty
									? css["product-button"]
									: util.join(
											"ut-theme-color",
											css["product-button--dirty"]
									  )
								: dirty
								? css["product-button"]
								: util.join(
										"ut-theme-color",
										css["product-button--dirty"]
								  )
						}`}
						children={"Изменить"}
						loading={props.loading}
						disabled={isChange && dirty}
					></Button>
				)}
			</div>
		</div>
	);
};

CartItemProduct.defaultProps = {
	text: "",
	params: [],
	id: 0,
	name: "",
	removeHandler: () => {
		alert("Not removed");
	},
	updateHandler: () => {},
};

CartItemProduct.propTypes = {
	text: PropTypes.string.isRequired,
	params: PropTypes.array.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	name: PropTypes.string.isRequired,
	removeHandler: PropTypes.func.isRequired,
};

export default CartItemProduct;
