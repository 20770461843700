import React from "react";
import css from "./styles/ReviewsTotal.module.scss";
import util from "../util/util";

const ReviewsTotal = (props) => {
	const totalsItems = props.ratingFields.map((item, index) => (
		<li key={index} className={css["totals-item"]}>
			<div className={css["totals-item-text"]}>
				<p>{item.title}</p>
				<p>{item.value}</p>
			</div>
			<div className={css["totals-item-gauge"]}>
				<span style={{ width: item.value * 10 + "%" }} />
			</div>
		</li>
	));
	return (
		<div className={util.join(css["totals-col"], "ut-theme-color")}>
			<h3 className={css["title"]}>Отзывы гостей</h3>
			<div className={css["totals-main"]}>
				<div className={css["totals-rate"]}>
					{parseFloat(props.overallRating)}
				</div>
				<div>
					<p className={css["totals-title"]}>{props.verbalRating}</p>
					<p className={css["totals-quantity"]}>
						{props.totalReviews} отзывов
					</p>
				</div>
			</div>
			<ul className={css["totals-items"]}>{totalsItems}</ul>
		</div>
	);
};

export default ReviewsTotal;
