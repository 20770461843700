import React, { Component } from "react";
import SearchResultsFilter from "./_SearchResultsFilter";
import SearchResultsList from "./_SearchResultsList";
import css from "./SearchResults.module.scss";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import apiDev from "../API/API.dev";
import util from "../util/util";
import withLoading from "../../hoc/Loading/Loading";
import SearchResultsMapItem from "./_SearchResultsMapItem";
import * as actions from "../../store/actions/index";
import Categories from "../../model/Categories";
/**
 * Mobile Detect
 */

const gridSize = () => {
	const SMALL = 399;
	const MED = 767;
	const GRID = {
		small: 1,
		med: 2,
		large: 3,
	};
	return window.innerWidth <= SMALL
		? GRID.small
		: window.innerWidth <= MED
		? GRID.med
		: GRID.large;
};
/**
 * Default category
 */
const defaultCategory = {
	id: 7,
	code: "acc",
	name: "Проживание",
	icon: "home",
	subcat: [],
};

class SearchResults extends Component {
	state = {
		subCategories: [],
		productsList: [],
		offset: 0,
		scrollRef: React.createRef(),
		endPartners: false,
		categoryPartners: [],
		hoverId: false,
		endOfBest: false,
		first: false,
		loadSize: gridSize(),
		arrayMobile: [],
		productListMobile: [],
		countMobileArray: 0,
		scrollRef: React.createRef(),
	};

	componentDidMount() {
		this.props.loading(true);
		window.addEventListener("resize", () => {
			const loadSize = gridSize();
			this.setState((state) => ({
				...state,
				loadSize,
			}));
		});
		this.extraData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.category != prevProps.category) {
			this.extraData();
			this.props.loading(true);
		}
		if (prevState.loadSize !== this.state.loadSize) {
			this.props.loading(true);
			const newState = {
				...this.state,
				productsList: [],
				categoryPartners: [],
				endOfBest: false,
				endPartners: false,
				first: false,
				hoverId: false,
				countMobileArray: 0,
				productListMobile: [],
				arrayMobile: [],
			};
			this.setState({ ...newState }, this.loadMore);
		}
	}
	getHoveredProduct = (e) => {
		this.setState({ hoverId: e });
	};
	getCurrentCategory() {
		return (
			this.props.category ||
			new Categories(this.props.categories).byId(7) ||
			defaultCategory
		);
	}

	guestPickHandler = (adults, children) => {
		if (adults && adults > 0) {
			this.setState((state) => ({
				...state,
				adults: parseInt(adults),
			}));
		} else {
			this.setState((state) => ({
				...state,
				children: parseInt(children),
			}));
		}
	};

	loadMore = () => {
		const checkSubCat = () => {
			return this.props.filter.subCategory === null
				? []
				: parseInt(this.props.filter.subCategory.id);
		};
		const currentCategory = { ...this.getCurrentCategory() };
		const params = {
			offset: this.state.endOfBest
				? this.state.offset + 3
				: this.state.offset,
			categoryId: parseInt(currentCategory.id),
			dateIn: this.props.filter.dateIn.format("YYYY-MM-DD"),
			dateOut: this.props.filter.dateOut.format("YYYY-MM-DD"),
			adults: this.props.filter.adults,
			children: this.props.filter.children,
			time: this.state.time,
			subcategoryId: checkSubCat(),
			sorting: this.props.sort,
			sort: this.props.sort,
		};
		if (this.state.endOfBest) {
			let productsList = [...this.state.productsList];
			let idBestProducts = productsList.map((el) => el.id);
			idBestProducts = Array.from(new Set(idBestProducts));
			let categoryPartners = [...this.state.categoryPartners];
			let notRepeatPatners = categoryPartners.filter(
				(el) => idBestProducts.indexOf(el.id) == -1
			);
			let productsListNorepeat = this.state.productsList.concat(
				notRepeatPatners
			);
			this.setState(
				(state) => ({
					...state,
					endPartners: true,
					productsList: productsListNorepeat,
				}),
				() => {
					const e = this.state.scrollRef.current;
					e.scrollTop = e.scrollHeight;
				}
			);
		} else {
			apiDev("search/BestProducts", params).then((response) => {
				let emptyArray = true;
				let makeFirst = response.data.length;
				let endPartners = false;
				let arrayMobile = [];
				let countMobileArray = 0;
				let productListMobile = [];
				if (response.data == false) {
					emptyArray = true;
					makeFirst = false;
				}
				if (
					!util.objectsEqual(currentCategory, this.props.category) &&
					this.props.category
				) {
					return;
				}
				if (
					response.data &&
					Array.isArray(response.data) &&
					response.data.length !== 0
				) {
					let productsList = [...response.data];
					let idBestProducts = productsList.map((el) => el.id);
					idBestProducts = Array.from(new Set(idBestProducts));
					let categoryPartners = [...this.state.categoryPartners];
					util.log(categoryPartners.length);
					let notRepeatPatners = categoryPartners.filter(
						(el) => idBestProducts.indexOf(el.id) == -1
					);
					if (
						productsList.length < 3 &&
						this.state.endPartners == false
					) {
						productsList = productsList.concat(notRepeatPatners);
						endPartners = true;
					}
					if (this.state.loadSize <= 2) {
						arrayMobile = productsList.concat(notRepeatPatners);
						const piece = arrayMobile.slice(
							countMobileArray,
							countMobileArray + 2
						);
						productListMobile = productListMobile.concat(piece);
						countMobileArray += 2;
					}
					this.setState(
						(state) => ({
							...state,
							endOfBest: emptyArray,
							productsList,
							first: makeFirst,
							endPartners,
							arrayMobile: arrayMobile,
							countMobileArray: countMobileArray,
							productListMobile: productListMobile,
						}),
						this.props.loading(false)
					);
				} else {
					let productsList = [...this.state.productsList];
					let idBestProducts = productsList.map((el) => el.id);
					idBestProducts = Array.from(new Set(idBestProducts));
					let categoryPartners = [...this.state.categoryPartners];
					let notRepeatPatners = categoryPartners.filter(
						(el) => idBestProducts.indexOf(el.id) == -1
					);
					let productsListNorepeat = this.state.productsList.concat(
						notRepeatPatners
					);
					this.setState(
						(state) => ({
							...state,
							endPartners: true,
							endOfBest: true,
							productsList: productsListNorepeat,
						}),
						this.props.loading(false)
					);
				}
			});
		}
	};

	//logic for add extra data start
	extraData = () => {
		const currentCategory = { ...this.getCurrentCategory() };
		const checkSubCat = () => {
			return this.props.filter.subCategory === null
				? []
				: parseInt(this.props.filter.subCategory.id);
		};
		const params = {
			categoryId: currentCategory.id,
			subcategoryId: checkSubCat(),
		};

		this.props.loading(true);
		apiDev("catalog/GetUnlimPartnersByCategory", params).then(
			(response) => {
				if (response.data == false) {
					this.extraData();
				}
				if (response.data && Array.isArray(response.data)) {
					const categoryPartners = response.data;
					const newState = {
						...this.state,
						productsList: [],
						categoryPartners,
						endOfBest: false,
						endPartners: false,
						first: false,
						hoverId: false,
						countMobileArray: 0,
						productListMobile: [],
						arrayMobile: [],
					};
					this.setState({ ...newState }, this.loadMore);
				} else {
					this.setState((state) => ({
						...state,
						end: true,
					}));
				}
			}
		);
	};

	//logic for add extra data end
	checkAllEnd = () => {
		if (this.state.endOfBest && this.state.endPartners === true) {
			return true;
		} else {
			return false;
		}
	};

	checkAllEndMobile = () => {
		if (this.state.countMobileArray >= this.state.arrayMobile.length) {
			return true;
		} else {
			return false;
		}
	};
	loadMoreMobile = () => {
		let countMobileArray = this.state.countMobileArray;
		const piece = this.state.arrayMobile.slice(
			this.state.countMobileArray,
			this.state.countMobileArray + 2
		);
		const productListMobile = this.state.productListMobile.concat(piece);
		countMobileArray += 2;
		this.setState((state) => ({
			...state,
			productListMobile,
			countMobileArray,
		}));
		this.props.loading(false);
	};

	render = () => {
		const currentCategory = this.getCurrentCategory();
		const mapElements = this.state.productsList.map((item, index) => {
			const position = util.getPosition(
				util.getPropertySafe(item, "params.position")
			);
			const price = parseInt(util.getPropertySafe(item, "params.price"));
			const content =
				price > 0 ? (
					<span>{util.currencyFormat(price)}</span>
				) : (
					<i className={`ut-ico-${currentCategory.icon}`}></i>
				);

			return (
				<SearchResultsMapItem
					key={index}
					data={{ ...item, category: { ...currentCategory } }}
					{...position}
					hoverId={this.state.hoverId}
					getHover={this.getHoveredProduct}
				>
					{content}
				</SearchResultsMapItem>
			);
		});

		const map = (
			<div className={css["map"]}>
				<div className={css["map-container"]}>
					<GoogleMapReact
						onClick={this.getHoveredProduct}
						bootstrapURLKeys={{
							key: "AIzaSyBk0yr_pnl0TRs9oQy0j6-qkOqMJR3WLwU",
						}}
						defaultCenter={{
							lat: 43.6829697,
							lng: 40.2491973,
						}}
						defaultZoom={12}
						yesIWantToUseGoogleMapApiInternals
					>
						{mapElements}
					</GoogleMapReact>
				</div>
			</div>
		);

		const filter = (
			<SearchResultsFilter
				title={currentCategory.name}
				subCategories={currentCategory.subcat}
				onGuestPick={this.guestPickHandler}
				onDatePick={this.props.setFilter}
				dateIn={this.props.filter.dateIn}
				dateOut={this.props.filter.dateOut}
				adults={this.props.filter.adults}
				children={this.props.filter.children}
				category={this.getCurrentCategory()}
				refresh={this.extraData}
			/>
		);

		return (
			<section>
				<div className="ut-section__container">
					{filter}
					{this.state.loadSize <= 2 ? (
						<div className={css["main"]} ref={this.state.scrollRef}>
							<SearchResultsList
								filter={{ ...this.props.filter }}
								category={this.getCurrentCategory()}
								onMoreBtnClick={this.loadMoreMobile}
								products={this.state.productListMobile}
								disableBtn={this.checkAllEndMobile()}
								hoverForMap={this.state.hoverForMap}
								getHover={this.getHoveredProduct}
								hoverId={this.state.hoverId}
								countBest={this.state.first}
								scrollRef={this.state.scrollRef}
								loadSize={this.state.loadSize}
							/>
							{map}
						</div>
					) : (
						<div className={css["main"]}>
							<SearchResultsList
								filter={{ ...this.props.filter }}
								scrollRef={this.state.scrollRef}
								category={this.getCurrentCategory()}
								onMoreBtnClick={this.loadMore}
								products={this.state.productsList}
								disableBtn={this.checkAllEnd()}
								hoverForMap={this.state.hoverForMap}
								getHover={this.getHoveredProduct}
								hoverId={this.state.hoverId}
								countBest={this.state.first}
								scrollRef={this.state.scrollRef}
								loadSize={this.state.loadSize}
							/>
							{map}
						</div>
					)}
				</div>
			</section>
		);
	};
}

const mapStateToProps = (state) => ({
	catalog: state.catalog,
	filter: state.user.customerData.filter,
	category: state.user.customerData.category,
	categories: state.catalog.categories,
	sort: state.user.customerData.sort,
});

const mapDispatchToProps = (dispatch) => ({
	setFilter: (filter) => dispatch(actions.setFilter(filter)),
});

export default connect(mapStateToProps)(withLoading(SearchResults));
