import React, { Component, Fragment } from "react";

import SearchResults from "./../components/SearchResults/SearchResults3";
import { connect } from "react-redux";
import SearchInput from "../components/SearchInput/SearchInput";
import * as actions from "../store/actions/index";
import Banner from "../components/Banner/Banner";
import history from "history/browser";
import { SEARCH_PREFIX } from "../components/util/routes";

class LayoutSearch extends Component {
	state = { results: [] };

	componentDidMount = () => {
		window.scrollTo(0, 0);

		const { term } = this.props.match.params;

		if (term !== this.props.term) {
			this.props.setTerm(term);
		}
		if (this.props.catalog.length > 0) {
			this.syncUrlCategory();
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.catalog.length !== prevProps.catalog.length) {
			this.syncUrlCategory();
		}
		window.scrollTo(0, 0);
		if (this.props.category != prevProps.category) {
			const newState = {
				...this.state,
				count: true,
			};
			// this.props.loading(true);

			this.setState(
				{
					...newState,
				},
				this.actualBanners
			);
		}
		if (prevProps.term !== this.props.term) {
			history.push(SEARCH_PREFIX + this.props.term);
		}
	}

	actualBanners = () => {
		let banner = {};

		if (this.props.banners.length > 0) {
			if (this.state.count) {
				let getAllId = this.props.banners.map((item) => {
					return item.categoryId;
				});

				for (let i = 0; i < this.props.banners.length; i++) {
					let find;
					if (this.props.category) {
						find = this.props.category.id
							? getAllId.includes(this.props.category.id)
								? this.props.category.id
								: "all"
							: "all";
					} else {
						find = "all";
					}

					if (this.props.banners[i].categoryId == find) {
						banner = this.props.banners[i];
					}
				}
			} else {
				const _rnd = Math.floor(Math.random() * 3);
				for (let i = 0; i < this.props.banners.length; i++) {
					if (i == _rnd) {
						banner = this.props.banners[i];
					}
				}
			}
		}

		return banner;
	};
	syncUrlCategory() {
		const { category } = this.props.match.params;
		if (category && this.props.catalog.length > 0) {
			const _currentCategory = this.props.catalog.filter(
				(item) => item.code === category
			);
			if (_currentCategory.length > 0) {
				this.props.setCategory({ ..._currentCategory[0] });
			}
		}
	}

	render = () => {
		const { term, category } = this.props.match.params;
		return (
			<Fragment>
				<SearchInput
					term={this.props.term}
					searchHandler={this.props.setTerm}
				/>
				<div className="ut-section__container ut-section__container--no-margin">
					<SearchResults urlCategoryCode={category} />
					<Banner actual={this.actualBanners()} />
				</div>
			</Fragment>
		);
	};
}

const mapStateToProps = (state) => ({
	catalog: state.catalog.categories,
	category: state.user.customerData.category,
	term: state.user.customerData.searchTerm,
	banners: state.catalog.banners,
});
const mapDispatchToProps = (dispatch) => ({
	setTerm: (term) => dispatch(actions.setTerm(term)),
	setCategory: (category) => dispatch(actions.setCategory(category)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSearch);
